import { mapGetters, mapState } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import MobileNav from '../mobile-nav/MobileNav.vue'
import Logout from '@/components/account/Logout.vue'
import IconLogo2 from '~/assets/icons/Logo.svg'
import IconLogo2White from '~/assets/icons/LogoWhite.svg'
import IconHamburger from '~/assets/icons/Hamburger.svg'
import IconHamburgerWhite from '~/assets/icons/HamburgerWhite.svg'
import IconCrossLarge from '~/assets/icons/CrossLarge.svg'
import IconCrossLargeWhite from '~/assets/icons/CrossLargeWhite.svg'
import IconCart from '~/assets/icons/Cart.svg'
import IconCartBold from '~/assets/icons/CartBold.svg'
import IconCartWhite from '~/assets/icons/CartWhite.svg'
import IconCartWhiteBold from '~/assets/icons/CartWhiteBold.svg'
import SearchIcon from '~/assets/icons/Search.svg'
import SearchIconBold from '~/assets/icons/SearchBold.svg'
import SearchIconWhite from '~/assets/icons/SearchWhite.svg'
import SearchIconWhiteBold from '~/assets/icons/SearchWhiteBold.svg'
import AccountLogin from '@/components/account/AccountLogin.vue'

export default {
  components: {
    IconCart,
    IconCartBold,
    IconCartWhite,
    IconCartWhiteBold,
    IconHamburger,
    IconHamburgerWhite,
    IconLogo2,
    IconLogo2White,
    IconCrossLarge,
    IconCrossLargeWhite,
    MobileNav,
    SearchIcon,
    SearchIconBold,
    SearchIconWhite,
    SearchIconWhiteBold,
    Logout,
    AccountLogin
  },

  mixins: [clickaway],

  props: {
    mainMenu: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data () {
    return {
      activeMenu: null,
      closeMenuFn: null,
      showNewsletter: false,
      transparentNavbarScrollActive: true,
      transparentNavbarActive: true,
      hoverSearch: false,
      hoverCart: false
    }
  },

  computed: {
    ...mapGetters({
      currentCountryCode: 'frontend/currentCountryCode',
      getCurrencyCodeByCountryCode: 'frontend/getCurrencyCodeByCountryCode',
      cart: 'centra-cart-v2/cart',
      settings: 'storyblok/settings',
      loggedIn: 'member/loggedIn'
    }),

    ...mapState({
      miniCartOpen: state => state.ui.miniCartOpen,
      searchOpen: state => state.ui.searchOpen,
      mobileSidebarOpen: state => state.ui.mobileSidebarOpen, // @todo this should be called mobileMenuOpen
      desktopSubmenuOpen: state => state.ui.desktopSubmenuOpen,
      transparentNavbar: state => state.header.transparentNavbar,
      whiteNavbarText: state => state.header.whiteNavbarText,
      panelIsOpen: state => state.header.panelIsOpen
    }),

    cartQty () {
      if (this.cart) {
        return this.cart?.items?.reduce((acc, cur) => {
          acc = cur.quantity + acc
          return acc
        }, 0)
      } else {
        return 0
      }
    }
  },
  watch: {
    mobileSidebarOpen (newValue) {
      this.transparentNavbarActive = !newValue
    },
    panelIsOpen (newValue) {
      this.transparentNavbarActive = !newValue
    }
  },

  mounted () {
    window.addEventListener('scroll', this.updateTransparentNavbarScrollActive)
  },

  unmounted () {
    window.removeEventListener('scroll', this.updateTransparentNavbarScrollActive)
  },

  methods: {
    updateTransparentNavbarScrollActive () {
      this.transparentNavbarScrollActive = window.scrollY < document.getElementById('header')?.clientHeight * 2
    },

    removeMember () {
      this.$store.dispatch('member/logout')
    },

    toggleMobileMenu () {
      !this.mobileSidebarOpen ? this.$store.dispatch('ui/showMobileSidebar') : this.$store.dispatch('ui/hideMobileSidebar')
      this.mobileSidebarOpen
        ? this.contentClick('menu', 'menu', 'hamburgerClicked', undefined)
        : this.contentClick('menu', 'menu', 'dropdownMenuClosed', undefined)
    },

    showSubMenu (menu = null) {
      clearTimeout(this.closeMenuFn)
      this.transparentNavbarActive = false

      if (menu) {
        this.activeMenu = menu
      }

      this.$store.dispatch('ui/showDesktopSubmenu')
    },

    hideSubMenu () {
      this.closeMenuFn = setTimeout(() => {
        this.transparentNavbarActive = true
        this.$store.dispatch('ui/hideDesktopSubmenu')
      }, 300)
    },

    toggleMiniCart (miniCartOpen) {
      this.contentClick('menu', 'miniCart', 'showMiniCart', undefined)
      if (miniCartOpen || !(this.cartQty > 0)) {
        this.$store.dispatch('ui/toggleMiniCart')
      } else {
        this.$router.push(this.$u('checkout/v2'))
      }
    },

    showAssistance () {
      this.$store.dispatch('assistance/showAssistanceSidebar')
      this.contentClick('menu', 'assistance', 'showAssistance', undefined)
    },

    toggleSearch () {
      !this.searchOpen ? this.$store.dispatch('ui/showSearch') : this.$store.dispatch('ui/hideSearch')
      this.contentClick('utilities', 'search', 'openSearch', undefined)
    },

    toggleNewsletter () {
      this.showNewsletter = !this.showNewsletter
      this.showNewsletter
        ? this.contentClick('menu', 'newsletter', 'showSubscribeField', undefined)
        : this.contentClick('menu', 'newsletter', 'hideSubscribeField', undefined)
    },

    contentClick (contentType, subContentType, itemID, options) {
      this.$analytics.selectContent(contentType, subContentType, itemID, options)
    },
  }
}
