import { mapGetters } from 'vuex'
import IconChevronDownMini from '~/assets/icons/ChevronDownMini.svg'
import IconChevronRightMini from '~/assets/icons/ChevronRightMini.svg'
import AccountLogin from '@/components/account/AccountLogin.vue'
import LogoutMobile from '~/components/account/LogoutMobile.vue'

export default {
  components: {
    IconChevronDownMini,
    IconChevronRightMini,
    AccountLogin,
    LogoutMobile
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    },
    countryCode: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      activeMenu: null,
      screenHeight: 1280
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings',
      loggedIn: 'member/loggedIn',
      headerHeight: 'header/headerHeight'
    }),
  },
  watch: {
    show () {
      this.activeMenu = null
    }
  },
  mounted () {
    this.screenHeight = window.innerHeight
    this.$store.dispatch('header/setHeaderHeight')
  },
  methods: {
    toggleActiveMenu (id) {
      this.activeMenu === id ? this.activeMenu = null : this.activeMenu = id
    },
    showAssistance () {
      this.$store.dispatch('assistance/showAssistanceSidebar')
      this.contentClick('submenu', 'showAssistance', undefined)
    },
    contentClick (subContentType, itemID, options) {
      this.$analytics.selectContent('menu', subContentType, itemID, options)
    }
  }
}
