import Register from '@/components/account/forms/Register.vue'
import Recover from '@/components/account/forms/Recover.vue'
import Login from '~/components/account/forms/Login.vue'

export default {
  name: 'AccountLogin',
  components: {
    Register,
    Recover,
    Login
  },
  props: {
    focusOnMount: {
      type: Boolean,
      default: false
    },
    headlineSize: {
      type: String,
      default: 'sm'
    },
    headline: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    redirectTo: {
      type: String,
      default: null
    },
    secretPage: {
      type: Boolean,
      default: false
    },
    completeAccount: {
      type: Boolean,
      default: false
    },
    defaultForm: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      loginActive: true,
      registerActive: false,
      recoverActive: false
    }
  },

  created () {
    if (this.defaultForm === 'login') {
      this.loginActive = true
      this.registerActive = false
      this.recoverActive = false
    } else if (this.defaultForm === 'register') {
      this.loginActive = false
      this.registerActive = true
      this.recoverActive = false
    } else if (this.defaultForm === 'recover') {
      this.loginActive = false
      this.registerActive = false
      this.recoverActive = true
    }
  },

  methods: {
    activateSection (section) {
      if (section === 'register') {
        this.recoverActive = false
        this.loginActive = false
        this.registerActive = true
      }
      if (section === 'login') {
        this.recoverActive = false
        this.loginActive = true
        this.registerActive = false
      }
      if (section === 'recover') {
        this.recoverActive = true
        this.loginActive = false
        this.registerActive = false
      }
    },
    loggedIn () {
      this.$emit('loggedIn')
    },
    loggingIn () {
      this.$emit('loggingIn')
    }
  }
}
