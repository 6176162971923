const tokenCookieName = '_hod_token'
const marketCookieName = '_hod_m'

export default {
  state () {
    return {
      token: undefined,
      member: undefined,
      memberInitialized: false,
      loggedIn: false,
      orders: [],
      ordersInitialized: false
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setMember (state, member) {
      state.member = member
      state.loggedIn = !!member
    },
    setMemberInitialized (state) {
      state.memberInitialized = true
    },
    setOrders (state, orders) {
      state.orders = orders
      state.ordersInitialized = true
    }
  },
  actions: {
    setCrmMarket ({ dispatch }, marketId) {
      if (process.client) {
        this.$cookies.set(marketCookieName, marketId, {
          path: '/',
          maxAge: 60 * 60 * 24 * 2,
          sameSite: 'lax'
        })
        dispatch('setMarket')
      }
    },
    setMarket ({ dispatch, rootGetters, getters, rootState }, marketId = undefined) {
      const marketCookie = this.$cookies.get(marketCookieName)
      const newMarketId = (marketCookie || (getters.loggedIn ? rootGetters['storyblok/settings'].secretVipCentraMarket : marketId))?.toString()
      if (newMarketId) {
        const newMarket = rootState['centra-market'].markets.find(x => x.market === newMarketId)
        try {
          dispatch('frontend/setCurrentContext', {
            market: newMarket.market,
            language: rootGetters['frontend/currentLanguageCode'],
            country: rootGetters['frontend/currentCountryCode']
          }, { root: true })
          dispatch('centra-cart-v2/setContext', { market: parseInt(newMarket.market) }, { root: true })
          dispatch('product/updateMarket', newMarket, { root: true })
        } catch (e) {
          this.$sentryMiddleware.captureError('Store Member setMarket', new Error(`newMarket market: ${newMarket?.market}, name: ${newMarket?.name}, markets: ${rootState['centra-market'].markets.map(x => x.market)}`, { cause: e }))
        }
      }
    },
    async initialize ({ commit, dispatch }) {
      const urlQuery = new URLSearchParams(window.location.search)
      const token = this.$cookies.get(tokenCookieName) || (localStorage.getItem(tokenCookieName) || urlQuery.get(tokenCookieName))
      const res = await this.$backendApi.post('/cart_v2/selection', { token })
      dispatch('centra-cart-v2/prepareAdyenDropIn', res.data, { root: true })
      dispatch('setMember', res.data.loggedIn)
      if (this.$cookies.get(marketCookieName)) {
        dispatch('setMarket')
      }
      commit('setMemberInitialized')

      if (!token && window.location.pathname.includes('checkout')) {
        this.$sentryMiddleware.captureWarning('Store Member Initialize, token not set', new Error(`Path: ${window.location.pathname}`))
      }
    },
    setMember ({ commit, dispatch }, member) {
      commit('setMember', member)
      if (member) {
        dispatch('setMarket')
        this.$analytics.userProperties({
          user_id: member.customer,
          email_address: member.email,
          phone_number: member.phoneNumber,
          first_name: member.firstName,
          last_name: member.lastName,
          address: member.address1,
          city: member.city,
          state: member.state,
          postal_code: member.zipCode,
          country: member.country
        })
      }
    },
    clearMember ({ commit, dispatch, rootGetters }) {
      commit('setMember', null)
      dispatch('setMarket', rootGetters['frontend/getMarketByCountryCode'](rootGetters['frontend/currentCountryCode']))
    },
    setToken ({ commit }, token) {
      if (token) {
        commit('setToken', token)
        this.$cookies.set(tokenCookieName, token, {
          path: '/',
          maxAge: 60 * 60 * 24 * 30,
          sameSite: 'lax'
        })
        localStorage.setItem(tokenCookieName, token)
      }
    },
    async registerMember ({ dispatch, rootGetters }, payload) {
      const memberList = rootGetters['storyblok/settings'].membersList
      const res = await this.$backendApi.post('/customer/register/' + memberList, {
        token: rootGetters['member/token'],
        payload
      })
      if (res.data.register && (!res.data.data.errors || res.data.data.errors?.length < 1) && res.data.data.loggedIn) {
        dispatch('setMember', res.data.data.loggedIn)
      }
      return res.data
    },
    async login ({ dispatch, getters }, { email, password }) {
      const res = await this.$backendApi.post('/customer/login', {
        token: getters.token,
        payload: { email, password }
      })
      if (res.data.loggedIn) {
        dispatch('setMember', res.data.loggedIn)
      }
      return res.data
    },
    async logout ({ dispatch, getters }) {
      const res = await this.$backendApi.post('/customer/logout', {
        token: getters.token
      })
      if (!res.data.errors) {
        dispatch('clearMember')
      }
    },
    async orders ({ commit, getters }) {
      if (!getters.ordersInitialized) {
        const res = await this.$backendApi.post('/customer/get-orders', {
          token: getters.token
        })
        if (res.data.orders) {
          commit('setOrders', res.data.orders)
        }
      }
    },
    async changeDetails ({ dispatch, getters }, details) {
      const res = await this.$backendApi.post('/customer/change-details', {
        token: getters.token,
        payload: details
      })
      if (res.data.loggedIn) {
        dispatch('setMember', res.data.loggedIn)
      }
      return res.data
    },
    async resetPasswordEmail ({ dispatch, getters }, email) {
      const res = await this.$backendApi.post('/customer/reset-password-email', {
        token: getters.token,
        payload: { email }
      })
      return res.data
    },
    async resetPassword ({ dispatch, getters }, { i, id, newPassword }) {
      const res = await this.$backendApi.post('/customer/reset-password', {
        token: getters.token,
        payload: { i, id, newPassword }
      })
      if (res.data.loggedIn) {
        dispatch('setMember', res.data.loggedIn)
      }
      return res.data
    },
    async changePassword ({ dispatch, getters }, { password, newPassword }) {
      const res = await this.$backendApi.post('/customer/change-password', {
        token: getters.token,
        payload: { password, newPassword }
      })
      return res.data
    }
  },
  getters: {
    token: state => state.token,
    member: state => state.member,
    memberInitialized: state => state.memberInitialized,
    loggedIn: state => state.loggedIn,
    orders: state => state.orders,
    ordersInitialized: state => state.ordersInitialized
  }
}
