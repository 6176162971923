export const getMenuId = menuId => `shared-content/menus/${menuId}`
export const menuComponentName = 'MainMenu'
export const getMarketSettingsId = (language, marketId) => ['settings', marketId, language].join('-')
const getTrustLogosId = (language, marketId) => ['trust-logos', marketId, language].join('-')
const settingsSlug = 'shared-content/settings/general'

/**
 * Fetch storyblok things such as menus, styles, pages and similar here
 */
export default {
  state () {
    return {
      stories: [],
      elementTranslations: {},
      links: [],
      tags: []
    }
  },

  mutations: {
    story: (state, payload) => {
      state.stories = [payload].concat(state.stories.filter(x => x.id !== payload.id))
    },
    elementTranslations (state, { locale, entries }) {
      // An object can be used if the response data is already indexed for us
      state.elementTranslations[locale] = entries
    },
    links (state, links) {
      // Turning this into an array so we can filter/find
      state.links = Object.values(links)
    },
    tags (state, tags) {
      // Turning this into an array so we can filter/find
      state.tags = tags
    }
  },

  actions: {
    /**
     * UI Elements in storyblok contains all translations for buttons, small
     * headlines and similar things that are not directly part of the content
     * management. We use SB for it because the datasource setup is very easily
     * manageable, has import/export and doesn't require deploys for translation
     * changes.
     */
    addStory ({ commit }, story) {
      commit('story', {
        id: story.id,
        data: story
      })
    },
    fetchElementTranslations ({ state, commit, rootGetters }, locale) {
      if (!state.elementTranslations[locale]) { // This is just so we can switch quickly with stuff in state
        return this.$backendApi.get(`storyblok/frontend/datasource/ui-element-translations${locale ? '/' + locale : ''}`).then((response) => {
          commit('elementTranslations', {
            locale,
            entries: response.data
          })
        }).catch((error) => {
          console.warn('fetchElementTranslations failed:', error)
        })
      }
    },

    fetchStory ({ commit, rootState, getters }, { id, params }) {
      let lookupId = id
      const link = getters.getLinkForSlug(id)
      if (link) {
        lookupId = link.id
      }
      const language = params?.language || rootState.frontend.currentLanguageCode
      return this.$backendApi.get(`storyblok/frontend/story/${lookupId}${language ? '/' + language : ''}`).then((response) => {
        commit('story', {
          id: lookupId,
          data: response.data
        })
        return response.data
      })
    },

    /**
     * Get a menu with all its children
     */
    fetchMenu ({ dispatch }, menuId) {
      return dispatch('fetchStory', {
        id: getMenuId(menuId)
      })
    },

    fetchSettings ({ dispatch }) {
      return dispatch('fetchStory', {
        id: settingsSlug
      })
    },

    /**
     * This fetches the entire site (link) structure in one big blob
     * so we can do simple lookups on which ID to get from a storyblok
     * slug in order to do lookups on translations. It also lets us
     * build alternate URLs so that the site switches actually works
     * and can show the alternate version or redirect to the front page
     * if it doesn't exist
     */
    fetchLinks ({ commit }) {
      return this.$backendApi.get('storyblok/frontend/links')
        .then((response) => {
          commit('links', response.data)
          return response.data
        })
    },
  },
  getters: {
    /**
     * Locale should be implicitly added from the magento state by a mixin
     * method so a language is transparent to the component.
     *
     * 1. Return translated value if it exists
     * 2. Return default if it exists
     * 3. Return `name' if nothing exists
     */
    getElementTranslation: (state, getters, rootState, rootGetters) => (name) => {
      const currentLanguageCode = rootGetters['frontend/currentLanguageCode']
      const translations = state.elementTranslations[currentLanguageCode] || []

      const entry = translations.find(x => x.name === name)
      if (entry) {
        return entry.dimension_value ? entry.dimension_value : entry.value
      }
      console.warn(`GO INTO STORYBLOK AND TRANSLATE THIS: ${name}`)
      return name
    },

    getStoryById: state => (id) => {
      if (typeof id === 'object') {
        // An SB link object, this should not be in nuxt
        id = id.id
      }
      const found = state.stories.find(x => parseInt(x.id) === parseInt(id))
      if (found) {
        return found.data
      }
      return null // So we can v-if
    },
    getStoryBySlug: (state, getters) => slug => getters.getStoryById(getters.getLinkForSlug(slug)),
    settings: (state, getters) => getters.getStoryById(getters.getLinkForSlug(settingsSlug))?.content,
    getMenuById: (state, getters) => menuId => getters.getStoryBySlug(getMenuId(menuId)),

    getLinkForSlug: state => slug => state.links.find((it) => {
      return it.slug === slug
    }),
    getLinkForUuid: state => uuid => state.links.find(it => it.uuid === uuid),
    getMarketSettings: state => (languageCode, marketId) => state.stories.find(it => it.id === getMarketSettingsId(languageCode, marketId)) || { id: null, data: [] },
    trustLogos: (state, getters, rootState, rootGetters) => state.stories.find(it => it.id === getTrustLogosId(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentMarketId'])) || { id: null, data: [] },
  }
}
