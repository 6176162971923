export const getLocalizedValue = (store, product, key) => {
  const localeObject = product.localized?.[store.state.frontend.currentLanguageCode] || product
  return localeObject[key] || product[key]
}

export const transformProduct = (store, product) => {
  const transformedMarkets = Object.keys(product.markets).reduce((acc, market) => {
    acc[market] = {}
    acc[market].pricesByPricelist = Object.keys(product.markets[market].pricesByPricelist).reduce((acc, pricelist) => {
      const priceInfo = product.markets[market]?.pricesByPricelist[pricelist]
      acc[pricelist] = {
        priceAsNumber: priceInfo.priceAsNumber,
        priceBeforeDiscountAsNumber: priceInfo?.priceBeforeDiscountAsNumber,
        price: priceInfo?.price,
        priceBeforeDiscount: priceInfo?.priceBeforeDiscount,
        discountPercent: priceInfo?.discountPercent,
      }
      return acc
    }, {})
    return acc
  }, {})
  const marketID = store.getters['frontend/currentMarketId']
  const priceInfo = transformedMarkets[marketID]?.pricesByPricelist[store.getters['frontend/currentPricelistId']]
  const media = product.media.filter(it => it.type === 'image' && it.sources?.original?.url)
  return {
    name: getLocalizedValue(store, product, 'name'),
    id: product.product,
    url: product.uri,
    sku: product.sku,
    styleId: product.pr_style_id,
    color: product.variantName,
    price: priceInfo?.priceAsNumber,
    originalPrice: priceInfo?.priceBeforeDiscountAsNumber,
    priceText: priceInfo?.price,
    originalPriceText: priceInfo?.priceBeforeDiscount,
    discount: priceInfo?.discountPercent,
    isOnSale: priceInfo?.discountPercent > 0,
    mainImage: media?.[0]?.sources?.original?.url,
    hoverImage: media?.[1]?.sources?.original?.url,
    packshotImage: media.find(image => image?.attributes?.image_packshot_key === '1')?.sources?.original?.url,
    items: Object.values(product.items).map(it => ({
      size: it.name,
      id: it.item,
      sku: it.sku,
      quantity: it.stockByMarket ? (it.stockByMarket[marketID] || 0) : 0,
      stockByMarket: it.stockByMarket
    })),
    categories: Object.values(product.categories).map(c => ({ name: c.name, uri: c.uri, category: c.category, sortOrder: c.sortOrder })),
    productType: product.folderName,
    collection: product.collectionName,
    brand: product.brandName,
    swatchProducts: product.swatchProducts?.map(swatch => ({
      swatch: swatch.swatch,
      product: swatch.product,
      url: swatch.url,
      name: swatch.name,
      variantName: swatch.variantName,
    })) || [],
    meta: {
      title: getLocalizedValue(store, product, 'metaTitle'),
      description: getLocalizedValue(store, product, 'metaDescription'),
      keywords: getLocalizedValue(store, product, 'metaKeywords')
    },
    noExport: product.pr_no_export_bool === '1',
    isPreOrder: product.pre_order === 'Yes',
    preOrderDeliveryDate: getLocalizedValue(store, product, 'pre_order_delivery_date'),
    canSubscribe: product.mp_notify_me_enable === '1',
    memberOnly: product.pr_member_only_bool === '1',
    description: getLocalizedValue(store, product, 'description'),
    fit: getLocalizedValue(store, product, 'pr_fit_and_sizing_id'),
    videoUrl: product.pr_video_id,
    sizeAndFittingTable: getLocalizedValue(store, product, 'pr_size_and_fitting_table'),
    denimWidthSizeAndFittingTable: getLocalizedValue(store, product, 'pr_size_and_fitting_dimensions_width'),
    denimLengthSizeAndFittingTable: getLocalizedValue(store, product, 'pr_size_and_fitting_dimensions_length'),
    material: getLocalizedValue(store, product, 'pr_material_value'),
    liningComposition: getLocalizedValue(store, product, 'pr_lining_composition_value'),
    goodChoiceMaterialText: getLocalizedValue(store, product, 'pr_good_choice_material_text_value'),
    garmentCareText: getLocalizedValue(store, product, 'pr_garment_care_text_value'),
    goodChoiceCareText: getLocalizedValue(store, product, 'pr_good_choice_care_text_value'),
    garmentOrigin: getLocalizedValue(store, product, 'countryOfOriginName'),
    images: media.map(image => image?.sources?.original?.url || image),
    variantProducts: product.variantProducts?.map(variant => ({
      product: variant.product,
      url: variant.url,
      color: variant.variantName
    })),
    relatedProducts: product.relatedProducts?.map(product => ({ product: product.product })),
    alsoLikeProducts: product.alsoLikeProducts?.map(product => ({ product: product.product })),
    markets: transformedMarkets
  }
}

/**
 * The store should for now keep a basic structure of the product tree and leave
 * all bigger and heavy lookups to route level lookups
 * A Full product always have full information and market data
 * A partial product might not have full information (but can) but will always have enough data for ProductCard and CartItem
 */
export default {
  namespaced: true,
  state () {
    return {
      products: []
    }
  },
  mutations: {
    products (state, products) {
      state.products = products.concat(state.products.filter((item) => {
        return !products.find(product => product.product === item.id)
      }))
    },
    updateMarket (state, market) {
      state.products.forEach((product) => {
        if (product.markets) {
          const marketID = market.market
          const priceInfo = product.markets[marketID]?.pricesByPricelist[this.getters['frontend/currentPricelistId']]
          product.price = priceInfo?.priceAsNumber
          product.originalPrice = priceInfo?.priceBeforeDiscountAsNumber
          product.priceText = priceInfo?.price
          product.originalPriceText = priceInfo?.priceBeforeDiscount
          product.discount = priceInfo?.discountPercent
          product.isOnSale = priceInfo?.discountPercent > 0
          product.items.forEach((item) => {
            item.quantity = item.stockByMarket ? (item.stockByMarket[marketID] || 0) : 0
          })
        }
      })
    }
  },
  actions: {
    /**
     * Looks up product from centra if not in store
     */
    lookupProduct ({ commit, getters, dispatch }, id) {
      if (!id && !id?.length) {
        return null
      }
      const product = getters.getFullProductById(id)
      if (product) {
        return product
      }
      return dispatch('updateProduct', id)
    },
    updateProduct ({ commit, getters }, id) {
      return this.$backendApi.get(`/products/${id}`).then((response) => {
        commit('products', response.data.map(product => transformProduct(this, product)))
        return response.data.map(product => transformProduct(this, product))
      }).catch((e) => {
        this.$sentryMiddleware.captureFatal('Centra Lookup Products', new Error(`Error in product/lookupProducts - ${id} - ${e}`, { cause: e }))
      })
    },
    addPartialProduct ({ commit, getters }, product) {
      const productInStore = getters.getPartialProductById(product.id)
      if (!productInStore) {
        commit('products', [product])
      } else if (!productInStore.markets) {
        commit('products', [{ ...productInStore, ...product }])
      }
    },
    addPartialProducts ({ dispatch }, products) {
      products.forEach(product => dispatch('addPartialProduct', product))
    },
    updateMarket ({ commit }, market) {
      commit('updateMarket', market)
    }
  },
  getters: {
    getFullProductById: state => id => state.products.find(x => x.id === id && !!x.markets),
    getFullProductByUrl: state => url => state.products.find(x => x.url === url && !!x.markets),
    getPartialProductById: state => id => state.products.find(x => x.id === id),
  }
}
