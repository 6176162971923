import { mapGetters } from 'vuex'
import IconCross from '~/assets/icons/CrossLarge.svg'

export default {
  name: 'NotifyPopUp',
  components: { IconCross },
  computed: {
    ...mapGetters({
      header: 'notify-pop-up/header',
      paragraph: 'notify-pop-up/paragraph',
      items: 'notify-pop-up/items'
    }),
  },
  methods: {
    hidePopUp () {
      this.$store.dispatch('notify-pop-up/hideNotifyMeSidebar')
    }
  }
}
