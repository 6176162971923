import { mapGetters } from 'vuex'
import SearchIcon from '~/assets/icons/Search.svg'

export default {
  name: 'Search',

  components: {
    SearchIcon
  },
  data: () => ({
    searchQuery: '',
  }),
  computed: {
    ...mapGetters({
      currentAutocomplete: 'algolia/getCurrentAutocomplete',
    }),
    autocompleteFacets () {
      return this.currentAutocomplete?.result?.facets || []
    },
    products () {
      return this.currentAutocomplete?.result?.products || []
    },
    totalProducts () {
      return this.currentAutocomplete?.result?.totalProducts || 0
    }
  },

  watch: {
    searchQuery (newQuery) {
      clearTimeout(this.timeout)

      if (newQuery.length) {
        // Waits a short while to see if user is still typing
        this.timeout = setTimeout(() => {
          this.queryAutocomplete(newQuery)
        }, 200)
      } else {
        this.queryAutocomplete('')
      }
    }
  },

  mounted () {
    this.queryAutocomplete('')
  },

  methods: {
    queryAutocomplete (query) {
      this.$store.dispatch('algolia/autocomplete', query)
    },

    searchSubmit () {
      // Separate from search() because we need to handle redirect rules
      const newPath = `${this.$u('search')}?q=${this.searchQuery}`
      this.$router.push(newPath)
      this.$store.dispatch('ui/hideSearch')
      this.$analytics.selectContent('utilities', 'search', 'applySearch', undefined)
      this.$analytics.search(this.searchQuery)
    },

    suggestionClick (facet, option) {
      const newPath = `${this.$u('search')}?q=&search-parameters=${encodeURIComponent(JSON.stringify({ filters: `${facet.key}:"${option.value}"` }))}`
      this.$router.push(newPath)
      this.$store.dispatch('ui/hideSearch')
      this.$analytics.selectContent('utilities', 'search', 'applySearch', undefined)
      this.$analytics.search(option.value)
    }
  }
}
