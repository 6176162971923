export default {
  state () {
    return {
      sizeGuideSidebarOpen: false
    }
  },

  mutations: {
    showSizeGuideSidebar (state) {
      state.sizeGuideSidebarOpen = true
    },

    hideSizeGuideSidebar (state) {
      state.sizeGuideSidebarOpen = false
    }
  },

  actions: {
    showSizeGuideSidebar ({ dispatch, commit }) {
      commit('showSizeGuideSidebar')

      return dispatch('ui/closeAll', null, { root: true })
    },

    hideSizeGuideSidebar ({ commit }) {
      commit('hideSizeGuideSidebar')
    }
  }
}
