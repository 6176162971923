import { mapState } from 'vuex'
import IconPlusMini from '~/assets/icons/PlusMini.svg'
import IconChevronDownMini from '~/assets/icons/ChevronDownMini.svg'
import IconInstagram from '~/assets/icons/Instagram.svg'
import IconFacebook from '~/assets/icons/Facebook.svg'

export default {
  components: {
    IconPlusMini,
    IconChevronDownMini,
    IconInstagram,
    IconFacebook
  },
  props: {
    footerMenu: {
      type: Object,
      required: true,
      default: () => {}
    },
    socialLinks: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeMenu: null
    }
  },
  computed: {
    ...mapState({
      countryInformation: state => state.dagmar.countryInformation
    }),
    paymentLogosVisibleInFooter () {
      return this.countryInformation?.content?.paymentLogosVisibleInFooter || false
    },
    paymentLogos () {
      return this.countryInformation?.content?.paymentLogos || ''
    },
    paymentLogosTitle () {
      return this.countryInformation?.content?.paymentLogosTitle
    },
    deliveryLogos () {
      return this.countryInformation?.content?.deliveryLogos || ''
    },
    deliveryLogosTitle () {
      return this.countryInformation?.content?.deliveryLogosTitle
    },
    USPs () {
      return this.countryInformation?.content?.shopUsps || ''
    },
    USPsTitle () {
      return this.countryInformation?.content?.shopUspsTitle
    }
  },
  watch: {
    show () {
      this.activeMenu = null
    }
  },
  methods: {
    toggleActiveMenu (id) {
      this.activeMenu === id ? this.activeMenu = null : this.activeMenu = id
    },
    beforeEnter (el) {
      el.style.height = '0'
    },
    enter (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave (el) {
      el.style.height = '0'
    },
    faIconName (name) {
      return name.replace('icon-', '')
    },
    contentClick (subContentType, itemID, options) {
      this.$analytics.selectContent('footer', subContentType, itemID, options)
    }
  }
}
