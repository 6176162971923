export default {
  namespaced: true,
  state: () => ({
    countryInformation: null
  }),
  mutations: {
    countryInformation (state, countryInformation) {
      state.countryInformation = countryInformation
    }
  },
  actions: {
    async fetchCountryInformation ({ commit, rootState }) {
      const language = rootState.frontend.currentLanguageCode
      const countryCode = rootState.frontend.currentCountryCode
      const countryInformation = await this.$backendApi.get(`storyblok/frontend/country-information/${countryCode}${language ? '/' + language : ''}`).then(response => response.data.stories[0])
        .catch((e) => {
          this.$sentryMiddleware.captureWarning('Dagmar Fetch Country Information', new Error('Dagmar Fetch Country Information', { cause: e }))
        })
      commit('countryInformation', countryInformation)
      return countryInformation
    }
  },
  getters: {
    countryInformation: state => state.countryInformation
  }
}
