import { mapGetters } from 'vuex'

export default {
  name: 'ErrorPage',
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings'
    }),
  },
  mounted () {
    this.$analytics.sendErrorEvent(this.error.statusCode, this.error.message)
  }
}
