import { mapGetters } from 'vuex'

export default {
  name: 'CookieBanner',
  data () {
    return {
      showCookieBanner: false,
      showManageCookies: false,
      functionalityStorage: true,
      analyticsStorage: true,
      adStorage: true,
      personalizationStorage: true,
      securityStorage: true,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings'
    }),
  },
  mounted () {
    const consent = this.$cookies.get('_hod_consent')
    if (!consent) {
      this.showCookieBanner = true
      this.analyticsStorage = this.settings.cookie_banner_analytics_cookies
      this.adStorage = this.settings.cookie_banner_ads_cookies
      this.personalizationStorage = this.settings.cookie_banner_personalization_cookies
      this.securityStorage = this.settings.cookie_banner_security_cookies
    } else {
      consent.personalization_storage = true
      consent.security_storage = true
      this.$cookies.set('_hod_consent', consent, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        sameSite: 'lax'
      })
    }
  },
  methods: {
    toggleManageCookie () {
      this.showManageCookies = !this.showManageCookies
    },
    saveCookieSetting () {
      const payload = {
        functionality_storage: this.functionalityStorage,
        analytics_storage: this.analyticsStorage,
        ad_storage: this.adStorage,
        personalization_storage: this.functionalityStorage, // TODO if we implement personalization change this
        security_storage: this.functionalityStorage // TODO if we implement personalization change this
      }
      window.consentListeners.forEach((callback) => {
        callback(payload)
      })
      this.$cookies.set('_hod_consent', payload, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        sameSite: 'lax'
      })
      this.showCookieBanner = false
      this.$analytics.consentGiven()
    }
  }
}
