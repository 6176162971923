export default {
  state () {
    return {
      assistanceSidebarOpen: false
    }
  },

  mutations: {
    showAssistanceSidebar (state) {
      state.assistanceSidebarOpen = true
    },

    hideAssistanceSidebar (state) {
      state.assistanceSidebarOpen = false
    }
  },

  actions: {
    showAssistanceSidebar ({ dispatch, commit }) {
      commit('showAssistanceSidebar')

      return dispatch('ui/closeAll', null, { root: true })
    },

    hideAssistanceSidebar ({ commit }) {
      commit('hideAssistanceSidebar')
    }
  }
}
