import Navbar from '@/components/navbar/Navbar.vue'

export default {
  components: { Navbar },
  props: {
    mainMenu: {
      type: Object,
      required: true,
      default: () => {}
    },
    topbarPlacement: {
      type: String,
      required: true,
      default: 'above'
    }
  }
}
