export class ContextError extends Error {}
export class CountryMissing extends ContextError {}
export class LanguageMissing extends ContextError {}

// Putting countries in order here will put them first in the selector in the header
export const countrySortOrder = [
]

/**
 * 'route' should be a vue-router route object
 */
export const routeToLookupParts = (route, defaultLocale, defaultMarketSlug) => {
  defaultLocale = defaultLocale || 'us'
  // Set up defaults
  const parts = {
    country: defaultMarketSlug, // This "works" until the default slug contains a language as well
    storyblokEntry: '',
    language: null // if not in url we use the country default from centra
  }

  if (route.query._storyblok) {
    // Inside storyblok edit mode
    parts.language = route.query._storyblok_lang === 'default' ? defaultLocale : route.query._storyblok_lang
    parts.storyblokEntry = route.query._storyblok
  } else {
    const routeParts = route.fullPath.split('/')

    const splitSlug = routeParts[1].split('?')[0].split('-')
    parts.country = splitSlug[0].split('?')[0]
    if (splitSlug[1]) {
      splitSlug.shift()
      parts.language = splitSlug.join('-')
    } else {
      // parts.language = getDefaultLanguageCode(parts.country)
    }
    parts.storyblokEntry = routeParts.slice(2).join('/')
  }

  return parts
}

// We're currently browsing inside storyblok and need to manage
// that in some way because SB urls do not match the live site urls
export const isBrowsingViaStoryblok = (route) => {
  return !!route.query._storyblok
}

export default {
  state () {
    return {
      currentCountryCode: null,
      currentLanguageCode: null,
      currentCampaignUri: null,
      currentContext: null,
      countries: [],
      languages: []
    }
  },

  mutations: {
    currentCountryCode (state, countryCode) {
      state.currentCountryCode = countryCode
    },
    currentLanguageCode (state, languageCode) {
      state.currentLanguageCode = languageCode
    },
    countries (state, countries) {
      state.countries = countries
    },
    languages (state, languages) {
      state.languages = languages
    },
    currentCampaignUri (state, campaignUri) {
      state.currentCampaignUri = campaignUri
    },
    currentContext (state, context) {
      state.currentContext = context
    },
    ssrHeaders (state, ssrHeaders) {
      state.ssrHeaders = ssrHeaders
    }
  },

  actions: {
    async setCurrentSite ({ state, dispatch }, lookupParts) {
      await dispatch('setCurrentCountryCode', lookupParts.country.toUpperCase())
      const languageCode = lookupParts.language
        ? lookupParts.language
        : state.countries.find(x => x.country.toUpperCase() === lookupParts.country.toUpperCase()).language
      if (!languageCode) {
        throw new LanguageMissing(`Country "${lookupParts.country}" doesn't have a language connected to it in Centra.`)
      }
      return dispatch('setCurrentLanguageCode', languageCode)
    },

    setCurrentCountryCode ({ state, commit, rootGetters }, countryCode) {
      if (countryCode === '') {
        // Should redirect, HAHA EXCEPTION AS FLOW CONTROL
        throw new Error('Redirect me to a country that exists')
      }
      const country = state.countries.find(x => x.country === countryCode)
      if (!country) {
        const e = new CountryMissing(`Country "${countryCode}" doesn't exist in any context. Make sure it has a market, pricelist and shipping method set in Centra.`)
        console.log(`Error in frontend/setCurrentCountryCode: ${e.message}`)
        throw e
      }
      commit('currentCountryCode', countryCode)
    },

    setCurrentLanguageCode ({ state, dispatch }, languageCode) {
      const language = state.languages.find(it => it.language.toLowerCase() === languageCode.toLowerCase())
      return dispatch('setCurrentLanguage', language)
    },

    setCurrentLanguage ({ state, commit, dispatch }, language) {
      const foundLanguage = state.languages.find(it => it?.language === language?.language)
      if (!foundLanguage) {
        this.$sentryMiddleware.captureInfo('Language Not Found', new Error(`language ${language}`))
        throw new LanguageMissing(`Language code ${language?.language} doesn't exist in the language list retrieved from Centra.`)
      }
      commit('currentLanguageCode', foundLanguage.language)
    },

    setCurrentCampaignUri ({ state, commit }, campaignUri) {
      this.$cookies.set('campaign', campaignUri)
      commit('currentCampaignUri', campaignUri)
    },
    setCurrentContext ({ state, commit }, context) {
      commit('currentContext', context)
    }
  },

  getters: {
    market: (state, getters, rootState) => rootState['centra-market'].markets.find(x => x.market === getters.currentMarketId),
    pricelist: (state, getters, rootState) => rootState['centra-pricelist'].pricelists.find(x => x.pricelist === getters.currentPricelistId),

    /**
     * These getters exist only so that we can automagically get the current
     * country/language code inside the centra client and not having to pass it in every
     * call all the time manually
     */
    currentCountry: state => state.countries.find(x => x.country.toUpperCase() === state.currentCountryCode.toUpperCase()),
    currentCountryCode: state => state.currentCountryCode?.toUpperCase(),
    currentLanguageCode: state => state.currentLanguageCode,
    currentMarketId: (state, getters) => state.currentContext?.market || getters.currentCountry?.market,
    currentPricelistId: (state, getters) => getters.currentCountry?.pricelist,
    currentContext: state => state.currentContext,
    currentCampaign: state => state.currentCampaignUri,
    currentLanguage: state => state.languages.find(it => it.language === state.currentLanguageCode),
    currentSiteLangSlug: (state, getters) => {
      const slugParts = [state.currentCountryCode]
      if (state.currentLanguageCode !== getters.currentCountry.language) {
        slugParts.push(state.currentLanguageCode)
      }
      return slugParts.join('-').toLowerCase()
    },

    getMarketByCountryCode: state => countryCode => state.countries.find(x => x.country.toUpperCase() === countryCode.toUpperCase())?.market,
    getCurrencyCodeByCountryCode: (state, getters, rootState) => (countryCode) => {
      const pricelistId = state.countries.find(x => x.country.toUpperCase() === countryCode.toUpperCase())?.pricelist
      return rootState['centra-pricelist'].pricelists.find(x => x.pricelist === pricelistId)?.currency.currency
    },

    /**
     * Sort the delivery countries set up in market depending on how big they are
     * as told by the client
     */
    countriesSorted (state) {
      const countries = [...state.countries]

      // First, sort alphabetically
      countries.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))

      // Then, put the main countries first
      countries.sort((a, b) => {
        if (countrySortOrder.includes(a.name) && !countrySortOrder.includes(b.name)) {
          return -1
        } else if (!countrySortOrder.includes(a.name) && countrySortOrder.includes(b.name)) {
          return 1
        } else {
          return countrySortOrder.indexOf(a.name) - countrySortOrder.indexOf(b.name)
        }
      })

      return countries
    },

    // Because i proxy through a getter we can get countries from API later
    availableLanguages: state => state.languages,

    ssrHeaders: (state) => {
      if (process.client) {
        return null
      } else {
        return state.ssrHeaders
      }
    }
  }
}
