import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1e0f16e2 = () => interopDefault(import('../pages/_context/account/index.vue' /* webpackChunkName: "pages/_context/account/index" */))
const _4228cbff = () => interopDefault(import('../pages/_context/career/index.vue' /* webpackChunkName: "pages/_context/career/index" */))
const _11c45647 = () => interopDefault(import('../pages/_context/checkout/index.vue' /* webpackChunkName: "pages/_context/checkout/index" */))
const _967ce64c = () => interopDefault(import('../pages/_context/complete-account/index.vue' /* webpackChunkName: "pages/_context/complete-account/index" */))
const _4179cf9e = () => interopDefault(import('../pages/_context/login/index.vue' /* webpackChunkName: "pages/_context/login/index" */))
const _79799954 = () => interopDefault(import('../pages/_context/recover.vue' /* webpackChunkName: "pages/_context/recover" */))
const _666aabe0 = () => interopDefault(import('../pages/_context/sign-up/index.vue' /* webpackChunkName: "pages/_context/sign-up/index" */))
const _4a6f2810 = () => interopDefault(import('../pages/_context/checkout/result.vue' /* webpackChunkName: "pages/_context/checkout/result" */))
const _6ec24fbe = () => interopDefault(import('../pages/_context/checkout/result-v2.vue' /* webpackChunkName: "pages/_context/checkout/result-v2" */))
const _87aa7752 = () => interopDefault(import('../pages/_context/checkout/v2.vue' /* webpackChunkName: "pages/_context/checkout/v2" */))
const _1262d7c4 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:context?/account",
    component: _1e0f16e2,
    name: "context-account"
  }, {
    path: "/:context?/career",
    component: _4228cbff,
    name: "context-career"
  }, {
    path: "/:context?/checkout",
    component: _11c45647,
    name: "context-checkout"
  }, {
    path: "/:context?/complete-account",
    component: _967ce64c,
    name: "context-complete-account"
  }, {
    path: "/:context?/login",
    component: _4179cf9e,
    name: "context-login"
  }, {
    path: "/:context?/recover",
    component: _79799954,
    name: "context-recover"
  }, {
    path: "/:context?/sign-up",
    component: _666aabe0,
    name: "context-sign-up"
  }, {
    path: "/:context?/checkout/result",
    component: _4a6f2810,
    name: "context-checkout-result"
  }, {
    path: "/:context?/checkout/result-v2",
    component: _6ec24fbe,
    name: "context-checkout-result-v2"
  }, {
    path: "/:context?/checkout/v2",
    component: _87aa7752,
    name: "context-checkout-v2"
  }, {
    path: "/*",
    component: _1262d7c4,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
