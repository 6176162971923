export const transformProduct = (store, product) => {
  const marketID = store.getters['frontend/currentMarketId']
  const priceInfo = product.markets ? product.markets[marketID]?.pricesByPricelist[store.getters['frontend/currentPricelistId']] : product
  const media = product.media.standard
  return {
    name: product.name,
    id: product.product,
    url: product.uri,
    sku: product.sku,
    styleId: product.pr_style_id,
    color: product.variantName,
    price: priceInfo?.priceAsNumber,
    originalPrice: priceInfo?.priceBeforeDiscountAsNumber,
    priceText: priceInfo?.price,
    originalPriceText: priceInfo?.priceBeforeDiscount,
    discount: priceInfo?.discountPercent,
    isOnSale: priceInfo?.discountPercent > 0,
    mainImage: media?.[0],
    hoverImage: media?.[1],
    packshotImage: product.mediaObjects.find(image => image?.attributes?.image_packshot_key === '1')?.sources?.standard?.[0]?.url,
    categories: Object.values(product.categories),
    collection: product.collectionName,
    brand: product.brandName,
    meta: {
      title: product.metaTitle,
      description: product.metaDescription,
      keywords: product.metaKeywords
    },
    noExport: product.pr_no_export_bool === '1',
    isPreOrder: product.pre_order === 'Yes',
    preOrderDeliveryDate: product.pre_order_delivery_date,
    canSubscribe: product.mp_notify_me_enable === '1',
    memberOnly: product.pr_member_only_bool === '1',
    description: product.description,
    fit: product.pr_fit_and_sizing_id,
    videoUrl: product.pr_video_id,
    sizeAndFittingTable: product.pr_size_and_fitting_table,
    denimWidthSizeAndFittingTable: product.pr_size_and_fitting_dimensions_width,
    denimLengthSizeAndFittingTable: product.pr_size_and_fitting_dimensions_length,
    material: product.pr_material_value,
    liningComposition: product.pr_lining_composition_value,
    goodChoiceMaterialText: product.pr_good_choice_material_text_value,
    garmentCareText: product.pr_garment_care_text_value,
    goodChoiceCareText: product.pr_good_choice_care_text_value,
    images: media.map(image => image?.sources?.original?.url || image),
  }
}

const handlePurchase = (store, placedOrder) => {
  store.$analytics.userProperties({
    email_address: placedOrder.address.email,
    phone_number: placedOrder.address.phoneNumber,
    first_name: placedOrder.address.firstName,
    last_name: placedOrder.address.lastName,
    address: placedOrder.address.address1,
    city: placedOrder.address.city,
    state: placedOrder.address.state,
    postal_code: placedOrder.address.zipCode,
    country: placedOrder.address.country
  })
  store.$analytics.purchase(placedOrder)
}

export default {
  state () {
    return {
      formHtml: '',
      formFields: {},
      customerAddress: {},
      placedOrder: undefined,
      errors: undefined,
      selection: {},
      previousSelection: {},
      location: {},
      adyenDropInLoading: false,
      availableShippingMethods: []
    }
  },
  mutations: {
    setFormHtml (state, formHtml) {
      state.formHtml = formHtml
    },
    setFormFields (state, formFields) {
      state.formFields = formFields
    },
    setCustomerAddress (state, address) {
      state.customerAddress = address
    },
    setPlacedOrder (state, order) {
      state.placedOrder = order
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    setSelection (state, selection) {
      state.previousSelection = state.selection
      state.selection = selection
    },
    setLocation (state, location) {
      state.location = location
    },
    setAdyenDropInLoading (state, loading) {
      state.adyenDropInLoading = loading
    },
    setAvailableShippingMethods (state, availableShippingMethods) {
      state.availableShippingMethods = availableShippingMethods
    }
  },
  actions: {
    setSelection ({ commit, dispatch }, selection) {
      if (selection && selection.items) {
        selection.items = selection.items.map((item) => {
          const product = transformProduct(this, item.product)
          dispatch('product/addPartialProduct', product, { root: true })
          item.productId = product.id
          item.product = undefined
          return item
        })
      }
      commit('setSelection', selection)
    },
    setPlacedOrder ({ commit, dispatch }, order) {
      if (order && order.items) {
        order.items = order.items.map((item) => {
          const product = transformProduct(this, item.product)
          dispatch('product/addPartialProduct', product, { root: true })
          item.productId = product.id
          item.product = undefined
          return item
        })
      }
      commit('setPlacedOrder', order)
    },
    setLocation ({ commit }, location) {
      commit('setLocation', location)
    },
    setAvailableShippingMethods ({ state, commit, rootGetters }, availableShippingMethods) {
      /** Loops all countryInformation and looks for the naming convention shippingEstimate
       * Will get slower as we add more attributes
       *  */
      const getShippingEstimate = (shippingName) => {
        const res = Object.entries(rootGetters['dagmar/countryInformation']?.content).find(([key, value]) => {
          return key.toLowerCase() === `shippingEstimate_${shippingName}`.toLowerCase()
        })
        if (res) {
          return res[1]
        }
        return undefined
      }

      const customOrder = {}
      rootGetters['dagmar/countryInformation'].content?.checkoutShippingOrder?.tbody?.forEach((row) => {
        customOrder[row.body[0].value.toLowerCase().replace(/\s/g, '')] = row.body[1].value
      })
      commit('setAvailableShippingMethods', availableShippingMethods
        .sort((a, b) => a.priceAsNumber - b.priceAsNumber)
        // custom order if defined in Storyblok
        .sort((a, b) => (customOrder[a.name.toLowerCase().replace(/\s/g, '')] || 100) - (customOrder[b.name.toLowerCase().replace(/\s/g, '')] || 100))
        // inject shippingMethodDescriptions from StoryBlok
        .map(method => (
          { ...method, estimate: getShippingEstimate(method.name.replaceAll(' ', '')) }
        )))
    },
    async setContext ({ rootGetters, getters }, context = undefined) {
      const payload = {
        token: rootGetters['member/token'],
        context: context || {
          market: parseInt(rootGetters['frontend/market'].market),
          pricelist: parseInt(rootGetters['frontend/pricelist'].pricelist),
          country: rootGetters['frontend/currentCountryCode'],
          language: rootGetters['frontend/currentLanguageCode']
        }
      }
      let isUpdate = false
      Object.keys(payload.context).forEach((key) => {
        if ((key === 'language' && getters.cartLocation[key].language !== payload.context[key]) || (key !== 'language' && getters.cartLocation[key] !== payload.context[key])) {
          isUpdate = true
        }
      })
      if (isUpdate && !window?.location?.href?.includes('checkout/result')) {
        try {
          return await this.$backendApi.post('/cart_v2/set-context', payload)
        } catch (e) {
          this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
        }
      }
    },
    async addItem ({ commit, dispatch, rootGetters }, item) {
      commit('setAdyenDropInLoading', true)
      try {
        const res = await this.$backendApi.post('/cart_v2/add-item-quantity', { token: rootGetters['member/token'], item, quantity: 1 })
        dispatch('initializeAdyenDropIn')
        const itemData = res.data.selection.items.find(it => it.item === item)
        this.$analytics.addToCart(itemData, 1)
        return res.data
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
      }
    },
    async updateItem ({ commit, dispatch, getters, rootGetters }, { item, quantity }) {
      commit('setAdyenDropInLoading', true)
      try {
        const res = await this.$backendApi.post('/cart_v2/set-item-quantity', { token: rootGetters['member/token'], lineId: item.line, quantity })
        dispatch('initializeAdyenDropIn')
        const qtyDiff = quantity - item.quantity
        if (qtyDiff < 0) {
          this.$analytics.removeFromCart(item, Math.abs(qtyDiff))
        } else {
          this.$analytics.addToCart(item, qtyDiff)
        }
        return res.data
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
      }
    },
    removeNoExportProducts ({ dispatch, getters, rootGetters }) {
      getters.cart?.items?.forEach((item) => {
        const product = rootGetters['product/getPartialProductById'](item.productId)
        if (product.noExport) {
          dispatch('notify-pop-up/setNotification',
            { header: 'Notify_Products_Removed', paragraph: 'Notify_Products_Removed_Export', item },
            { root: true }
          )
          dispatch('updateItem', { item, quantity: 0 })
        }
      })
    },
    async addVoucher ({ commit, dispatch, rootGetters }, voucher) {
      commit('setAdyenDropInLoading', true)
      try {
        const res = await this.$backendApi.post('/cart_v2/add-voucher', { token: rootGetters['member/token'], voucher })
        dispatch('initializeAdyenDropIn')
        return res.data
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
      }
    },
    async removeVoucher ({ commit, dispatch, rootGetters }, voucher) {
      commit('setAdyenDropInLoading', true)
      try {
        const res = await this.$backendApi.post('/cart_v2/remove-voucher', { token: rootGetters['member/token'], voucher })
        dispatch('initializeAdyenDropIn')
        return res.data
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
      }
    },
    async setShippingMethod ({ commit, dispatch, rootGetters }, shippingMethod) {
      commit('setAdyenDropInLoading', true)
      try {
        const res = await this.$backendApi.post('/cart_v2/set-shipping-method', { token: rootGetters['member/token'], shippingMethod })
        this.$analytics.addShippingInfo(res.data.selection, shippingMethod)
        dispatch('initializeAdyenDropIn')
        return res.data
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
      }
    },
    prepareAdyenDropIn ({ commit }, selection) {
      const adyenDropIn = selection.paymentMethods.find(element => element.paymentMethodType === 'adyen_drop_in')
      if (adyenDropIn) {
        const script = document.createElement('script')
        script.src = adyenDropIn.clientSide.externalScript
        script.defer = true
        document.body.appendChild(script)
      }
    },
    async initializeAdyenDropIn ({ commit, dispatch, getters, rootGetters }) {
      if (window?.location?.href?.includes('checkout')) {
        try {
          if (!getters.cartEmpty) {
            const payload = {
              token: rootGetters['member/token'],
              country: rootGetters['frontend/currentCountryCode'],
            }
            try {
              const res = await this.$backendApi.post('/cart_v2/checkout/initiate-adyen-drop-in', payload)
              if (res.data.formHtml) {
                commit('setFormHtml', res.data.formHtml)
                commit('setFormFields', res.data.formFields)
              }
              if (res.data.errors) {
                const errors = res.data.errors
                if (errors.stock || errors.item) {
                  getters.previousCart.items.forEach((item) => {
                    const updatedItem = getters.cart.items.find(it => it.item === item.item)
                    if (updatedItem?.quantity !== item.quantity) {
                      dispatch('notify-pop-up/setNotification',
                        { header: 'Notify_Products_Removed', paragraph: 'Notify_Products_Removed_Stock', item: { ...item, quantity: (updatedItem?.quantity || 0) - item.quantity } },
                        { root: true }
                      )
                    }
                  })
                  delete errors.stock
                  delete errors.item
                }
                if (Object.keys(errors).length > 0) {
                  commit('setErrors', errors)
                }
              }
              commit('setAdyenDropInLoading', false)
            } catch (e) {
              this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
            }
          }
        } catch (e) {
          this.$sentryMiddleware.captureFatal('Centra Cart V2 INITIALIZE ADYEN', new Error('Could not initiate adyen', { cause: e }))
        }
      }
    },
    async updateAdyenDropInAddress ({ state, commit, dispatch, rootGetters }, address) {
      this.$analytics.userProperties({
        email_address: address.email,
        phone_number: address.phoneNumber,
        first_name: address.firstName,
        last_name: address.lastName,
        address: address.address1,
        city: address.city,
        state: address.state,
        postal_code: address.zipCode,
        country: address.country
      })
      commit('setCustomerAddress', address)
      const payload = {
        token: rootGetters['member/token'],
        address
      }
      try {
        await this.$backendApi.put('/cart_v2/checkout/update-fields', payload)
        commit('setAdyenDropInLoading', false)
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
      }
    },
    async sendAdyenDropInCallback ({ state, commit, dispatch, rootGetters }, details) {
      const payload = {
        token: rootGetters['member/token'],
        address: state.customerAddress,
        paymentMethodSpecificFields: details.paymentMethodSpecificFields
      }
      try {
        const res = await this.$backendApi.post('/cart_v2/checkout/callback', payload)
        if (details.responseEventRequired) {
          if (res.data.action === 'redirect') {
            location.href = res.data.url
          } else if (res.data.errors?.paymentMethod) {
            const self = this
            setTimeout(async () => {
              const newlyPlacedOrder = await dispatch('getOrderReceipt')
              if (!newlyPlacedOrder) {
                commit('setErrors', res.data.errors)
                self.$sentryMiddleware.captureWarning('CALLBACK CUSTOM FIX', new Error('Callback custom fix failed: ' + JSON.stringify(res.data.errors)))
              }
            }, 5000)
          } else if (res.data.errors) {
            commit('setErrors', res.data.errors)
            // TODO handle errors here maybe also or in setErrors?
            this.$sentryMiddleware.captureError('Initiate Adyen Errors', new Error('Initiate Adyen Errors: ' + JSON.stringify(res.data.errors)))
          } else if (res.data.action === 'javascript') {
            // eslint-disable-next-line no-eval
            eval(res.data.code)
          } else if (res.data.action === 'success') {
            dispatch('setPlacedOrder', res.data.order)
            handlePurchase(this, res.data.order)
          }
        }
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
      }
    },
    async getPaymentResult ({ state, commit, dispatch, rootGetters }, paymentMethodFields) {
      const payload = {
        token: rootGetters['member/token'],
        address: state.customerAddress,
        paymentMethodFields
      }
      try {
        const res = await this.$backendApi.post('/cart_v2/checkout/result', payload)
        if (res.data.errors) {
          this.$sentryMiddleware.captureInfo('Centra Checkout Payment Result', new Error(`Payload: ${JSON.stringify(payload)}`))
        }
        if (res.data.order) {
          dispatch('setPlacedOrder', res.data.order)
          handlePurchase(this, res.data.order)
        } else {
          const self = this
          setTimeout(async () => {
            const newlyPlacedOrder = await dispatch('getOrderReceipt')
            if (!newlyPlacedOrder) {
              commit('setErrors', res.data.errors)
              await self.$router.push('v2')
              this.$sentryMiddleware.captureWarning('RESULT CUSTOM FIX', new Error(`Errors: ${JSON.stringify(res.data?.errors)}`))
            }
          }, 5000)
        }
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
        await this.$router.push('v2')
        commit('setErrors', { unexpectedError: true })
      }
    },
    /***
     * To get order receipt
     * @param commit
     * @param dispatch
     * @param rootGetters
     * @returns {Promise<false>} used in paymentResult since we have a bug where the order goes through without true payment result
     */
    async getOrderReceipt ({ dispatch, rootGetters }) {
      const payload = {
        token: rootGetters['member/token']
      }
      try {
        const res = await this.$backendApi.post('/cart_v2/checkout/receipt', payload)
        if (res.data.order) {
          dispatch('setPlacedOrder', res.data.order)
          handlePurchase(this, res.data.order)
        }
        const minutesSinceOrder = (new Date() - new Date(new Date(res.data.order?.date).toLocaleString('en', { timeZone: 'Europe/Stockholm' }))) / (60 * 1000)
        return minutesSinceOrder < 5
      } catch (e) {
        this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
        return false
      }
    },
    setAdyenDropInLoading ({ commit }, loading) {
      commit('setAdyenDropInLoading', loading)
    },
  },
  getters: {
    cart: state => state.selection,
    previousCart: state => state.previousSelection,
    cartLocation: state => state.location,
    cartEmpty: state => state.selection === undefined || (state.selection.items && state.selection.items.length === 0),
    shipping: state => state.selection?.shippingMethod,
    formHtml: state => state.formHtml,
    placedOrder: state => state.placedOrder,
    errors: state => state.errors,
    adyenDropInLoading: state => state.adyenDropInLoading,
    availableShippingMethods: state => state.availableShippingMethods,
  }
}
