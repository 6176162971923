export default {
  state () {
    return {
      header: '',
      paragraph: '',
      items: []
    }
  },

  mutations: {
    hideNotifyMeSidebar (state) {
      state.header = ''
      state.paragraph = ''
      state.items = []
    },
    setNotification (state, { header, paragraph, item }) {
      state.header = header
      state.paragraph = paragraph
      state.items.push(item)
    }
  },

  actions: {
    hideNotifyMeSidebar ({ commit }) {
      commit('hideNotifyMeSidebar')
    },
    setNotification ({ commit }, { header, paragraph, item }) {
      commit('setNotification', { header, paragraph, item })
    },
  },

  getters: {
    header: state => state.header,
    paragraph: state => state.paragraph,
    items: state => state.items
  }
}
