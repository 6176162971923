import { mapGetters } from 'vuex'

export default {
  name: 'OpenChatButton',
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings'
    }),
    showChatButton () {
      return this.settings.open_chat_button
    },
    clientServiceHours () {
      const date = new Date(new Date(Date.now()).toLocaleString('en-US', { timeZone: 'Europe/Stockholm' }))
      return this.settings.customer_service_open_days.includes(date.getDay().toString()) &&
        Number(this.settings.customer_service_open_hour) <= date.getHours() &&
        Number(this.settings.customer_service_closing_hour) >= date.getHours()
    }
  },
  methods: {
    sendEvent () {
      this.$analytics.selectContent('support', 'chat', 'openChatButton', undefined)
    }
  }
}
