/**
 * All GA4 events goes through here, the following documentation is good to look at:
 * No function should be called from the server (except for gaIsInAbTest)
 * Which means the functions should be called the earliest in mounted lifecycle hook
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 * https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#data-layer
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#migration_from_legacy_ecommerce_data_layer_objects
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/config#page_referrer
 * @param $config
 * @param store vuex store
 * @param inject
 */

import crypto from 'crypto'
import { baseAnalyticsTool } from '@/utils/base-analytics'

export const gtmGa4Tool = ({ $config, store, route }, abTests) => {
  return {
    ...baseAnalyticsTool({ $config, store, route }, abTests),
    accountCheckout: () => {
      sendGa4NormalEvent('account_checkout', {})
    },
    addPaymentInfo: (cart, paymentMethod = '') => {
      sendGa4EcomEvent('add_payment_info', {
        currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
        value: cart.totals.grandTotalPriceAsNumber,
        coupon: ''.concat(Object.keys(cart.discounts.vouchers)),
        payment_type: paymentMethod.length > 0 ? paymentMethod : cart.paymentMethodName,
        items: createItemArray(store, cart.items)
      })
    },
    addShippingInfo: (cart, shippingMethod) => {
      sendGa4EcomEvent('add_shipping_info', {
        currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
        value: cart.totals.grandTotalPriceAsNumber,
        coupon: ''.concat(Object.keys(cart.discounts.vouchers)),
        shipping_tier: cart.shippingMethodName,
        items: createItemArray(store, cart.items)
      })
    },
    addToCart: (item, quantity) => {
      sendGa4EcomEvent('add_to_cart', {
        currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
        value: item.priceEachBeforeDiscountAsNumber,
        items: [createItem(store, { ...item, quantity })]
      })
    },
    beginCheckout: (cart) => {
      sendGa4EcomEvent('begin_checkout', {
        currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
        value: cart.totals.grandTotalPriceAsNumber,
        coupon: ''.concat(Object.keys(cart.discounts.vouchers)),
        items: createItemArray(store, cart.items)
      })
    },
    consentGiven: () => {
      sendGa4NormalEvent('consent_given', {})
    },
    login: () => {
      sendGa4NormalEvent('login', {
        method: 'DagmarLogin'
      })
    },
    purchase: (order) => {
      sendGa4EcomEvent('purchase', {
        currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
        transaction_id: order.order,
        value: order.totals.grandTotalPriceAsNumber,
        sha1email: crypto.createHash('sha1').update(order.address.email).digest('hex'),
        sha256email: crypto.createHash('sha256').update(order.address.email).digest('hex'),
        purchase_date: order.date.replace(' ', 'T'),
        // affiliation: '',
        coupon: Array.isArray(order.discounts.vouchers) ? order.discounts.vouchers.reduce((accumulator, currentValue) => accumulator + currentValue.voucher, '') : ''.concat(Object.keys(order.discounts.vouchers)),
        shipping: order.totals.shippingPriceAsNumber,
        tax: order.totals.grandTotalPriceTaxAsNumber,
        items: createItemArray(store, order.items)
      })
    },
    removeFromCart: (item, quantity) => {
      sendGa4EcomEvent('remove_from_cart', {
        currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
        value: -item.priceEachBeforeDiscountAsNumber * quantity,
        items: [createItem(store, { ...item, quantity })]
      })
    },
    search: (query) => {
      sendGa4NormalEvent('search', {
        search_term: query
      })
    },
    /**
     * An event for all contents that gets a click, see different options parameters to choose from
     * @param contentType
     * @param contentSubType
     * @param itemID
     * @param options [position, product_name, form_name, form_choice, video, image]
     */
    selectContent: (contentType, contentSubType, itemID, options) => {
      clearGa4NormalEvent({
        position: null,
        product_name: null,
        form_name: null,
        form_choice: null,
        video: null,
        image: null
      })
      sendGa4NormalEvent('select_content', Object.assign({
        content_type: contentType,
        content_sub_type: contentSubType,
        item_id: itemID
      }, options))
    },
    selectItem: (product, list, position) => {
      sendGa4EcomEvent('select_item', {
        item_list_id: list.concat('_', Math.floor(position / 3).toString()),
        item_list_name: list,
        items: [createItem(store, product, list.concat('_', Math.floor(position / 3).toString()), list, position)]
      })
    },
    signUp: (type) => {
      sendGa4NormalEvent('sign_up', {
        method: 'Dagmar',
        form_name: type
      })
    },
    viewCart: (cart) => {
      if (cart?.items?.length > 0) {
        sendGa4EcomEvent('view_cart', {
          currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
          value: cart.totals.grandTotalPriceAsNumber,
          items: createItemArray(store, cart.items)
        })
      }
    },
    viewItem: (product) => {
      sendGa4EcomEvent('view_item', {
        currency: store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
        value: product.priceAsNumber,
        items: [createItem(store, product)]
      })
    },
    viewItemList: (product, list, position) => {
      queueViewItemListEvent(product, list, position, store)
    },
    pageView: (pageType, pageTitle, pageSubType, pageLastUpdate = 0) => {
      window.custom_referrer_url = window.custom_referrer_url || document.referrer
      sendGa4NormalEvent('page_view', {
        page_type: pageType,
        page_sub_type: pageSubType || 'other',
        page_last_update: pageLastUpdate,
        page_title: pageTitle,
        language: store.getters['frontend/currentCountryCode'],
        screen_resolution: window.screen.width.toString().concat('x', window.screen.height.toString()),
        window_resolution: window.innerWidth.toString().concat('x', window.innerHeight.toString()),
        custom_referrer_url: window.custom_referrer_url
      })
      window.custom_referrer_url = window.location.href
    },
    /**
     * @param data should be structured accordingly
     * {
     *         user_id,
     *         email_address,
     *         phone_number,
     *         first_name,
     *         last_name,
     *         city,
     *         state,
     *         postal_code,
     *         country
     *       }
     */
    userProperties: (data) => {
      const containerId = store.getters['storyblok/settings'][($config.gtmMode === 'stage' ? 'stage_' : '') + 'gtm_container_id']
      window.dataLayer.push({
        user_properties: {
          email_address: data.email_address || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.email_address || undefined) : undefined),
          phone_number: data.phone_number || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.phone_number || undefined) : undefined),
          first_name: data.first_name || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.first_name || undefined) : undefined),
          last_name: data.last_name || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.last_name || undefined) : undefined),
          address: data.address || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.address || undefined) : undefined),
          city: data.city || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.city || undefined) : undefined),
          state: data.state || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.state || undefined) : undefined),
          postal_code: data.postal_code || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.postal_code || undefined) : undefined),
          country: data.country || (window?.google_tag_manager ? (window?.google_tag_manager[containerId]?.dataLayer?.get('user_properties')?.country || undefined) : undefined)
        }
      })
    },
    croTestApplied: (testName, testVersion, formChoice = undefined) => {
      const abTest = abTests.find(({ name }) => name === testName)
      window.abTestApplied = window.abTestApplied || []
      if ((abTest && abTest.startDate <= Date.now() && Date.now() <= abTest.endDate) && !window.abTestApplied.includes(abTest.id)) {
        sendGa4NormalEvent('cro_test_applied', {
          form_name: abTest.name + '_v' + testVersion,
          form_choice: formChoice
        })
        window.abTestApplied.push(abTest.id)
      }
    },
    croTestConversion: (testName, conversionNumber, formChoice = undefined) => {
      const abTest = abTests.find(({ name }) => name === testName)
      if (abTest && abTest.startDate <= Date.now() && Date.now() <= abTest.endDate) {
        sendGa4NormalEvent('cro_test_conversion' + conversionNumber, {
          form_name: abTest.name,
          form_choice: formChoice
        })
      }
    },
    sendErrorEvent: (errorCode, errorMessage) => {
      sendGa4NormalEvent('error_page', {
        form_name: errorCode,
        form_choice: errorMessage
      })
    }
  }
}

/**
 * Clears specific parameters in data layer
 * @param data
 */
function clearGa4NormalEvent (data) {
  const payload = {
    ga4_data: data
  }
  window.dataLayer.push(payload)
}

/**
 * Sends normal events (not ecommerce events):
 * @param eventName according to GA4 standards
 * @param data the data sent together with the event
 */
function sendGa4NormalEvent (eventName, data) {
  const payload = {
    event: 'ga4_' + eventName,
    ga4_data: data,
    eventId: Math.floor(Math.random() * 2147483647)
  }
  window.dataLayer.push(payload)
}

/**
 * Sends ecommerce events see:
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
 * @param eventName according to GA4 standards
 * @param data the data sent together with the event
 */
function sendGa4EcomEvent (eventName, data) {
  window.dataLayer.push({ ecommerce: null })
  const payload = {
    event: 'ga4_' + eventName,
    ecommerce: data,
    eventId: Math.floor(Math.random() * 2147483647)
  }
  window.dataLayer.push(payload)
}

/**
 * Creates an item array according to GA4 standard, see under item:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info
 * @param store
 * @param items the array of the items
 * @param listId the PLP id
 * @param listName the PLP name
 * @param position the position in PLP
 */
function createItemArray (store, items, listId = undefined, listName = undefined, position = undefined) {
  const jsonItems = []
  items.forEach((item) => {
    jsonItems.push(createItem(store, item, listId, listName, position))
  })
  return jsonItems
}

/**
 * Creates an item array according to GA4 standard, see under item:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info
 * @param store
 * @param item either cartItem, product or productId
 * @param list the PLP id
 * @param listName the PLP name
 * @param position the position in PLP
 * @returns {{quantity, coupon: (string), item_id: *, discount: *, index, item_name: ((function(): *)|*), item_category2: *, item_category3: *, item_brand: *, item_category: (string), location_id: *, item_list_id, item_list_name, price: *, currency, item_variant: *}}
 */
function createItem (store, item, list = undefined, listName = undefined, position = undefined) {
  const product = store.getters['product/getPartialProductById'](item.productId || (item.id || item))
  const currency = store.getters['frontend/pricelist']?.currency?.uri.toUpperCase()
  return {
    item_id: product.sku,
    item_name: product.name,
    // affiliation: undefined,
    coupon: product.isOnSale ? 'discounted' : '',
    currency,
    discount: item?.originalPrice - item?.price || product.originalPrice - product.price, // total monetary
    index: position,
    item_brand: product.brand,
    item_category: product.styleId,
    item_category2: product.collection,
    item_category3: product.productType,
    item_category4: item.sku,
    // item_category5: undefined,
    item_list_id: list,
    item_list_name: listName,
    item_variant: product.color,
    location_id: product.url,
    price: product.price,
    quantity: item?.quantity || 1
  }
}

/**
 * Queues up to 20 items and then pushes as one event
 * @param product
 * @param list
 * @param position
 * @param store
 */
function queueViewItemListEvent (product, list, position, store) {
  window.queuedViewItemsInList = window.queuedViewItemsInList || []
  window.queuedViewItemsInList.push(createItem(store, product, store.getters['frontend/pricelist']?.currency?.uri.toUpperCase(),
    list.concat('_', Math.floor(position / 3).toString()), list, position, true))

  const sendViewItemListEvents = () => {
    while (window.queuedViewItemsInList.length > 0) {
      const popItems = window.queuedViewItemsInList.filter((item) => { return item.item_list_id === window.queuedViewItemsInList[0].item_list_id })
      sendGa4EcomEvent('view_item_list', {
        item_list_id: popItems[0].item_list_id,
        item_list_name: popItems[0].item_list_name,
        items: popItems
      })
      window.queuedViewItemsInList = window.queuedViewItemsInList.filter((item) => { return item.item_list_id !== popItems[0].item_list_id })
    }
  }

  clearTimeout(window.queuedViewItemsListEventTimer)
  if (window.queuedViewItemsInList.length >= 20) {
    sendViewItemListEvents()
  } else {
    window.queuedViewItemsListEventTimer = setTimeout(sendViewItemListEvents, 500)
  }
}
