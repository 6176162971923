import { routeToLookupParts } from './frontend'

export default {
  actions: {
    async nuxtServerInit ({ dispatch, state, rootState }, { req, route, redirect, $config }) {
      const lookupParts = routeToLookupParts(route, $config.defaultLocale, $config.defaultMarketSlug)

      try {
        await dispatch('frontend/setCurrentSite', lookupParts)
        await dispatch('storyblok/fetchLinks')
        await Promise.all([
          dispatch('storyblok/fetchMenu', 'footer-menu'),
          dispatch('storyblok/fetchMenu', 'main-menu'),
          dispatch('storyblok/fetchSettings'),
          dispatch('storyblok/fetchElementTranslations', rootState.frontend.currentLanguageCode),
        ]).catch((e) => {
          this.$sentryMiddleware.captureFatal('Couldn\'t fetch menu(s) or settings', new Error('Couldn\'t fetch menu(s) or settings', { cause: e }))
        })
      } catch (error) {
        if (state.frontend.currentCountryCode) {
          if (lookupParts.language) {
            const redirectTo = `/${state.frontend.currentCountryCode.toLowerCase()}/${req.originalUrl.split('/').slice(2).join('/')}`
            await redirect(`${redirectTo}`)
          } else {
            this.$sentryMiddleware.captureFatal('Couldn\'t set the market', new Error('Couldn\'t set the market', { cause: error }))
            throw error // The error is not about setting the market
          }
        }
        const countryCode = req.countryCode || ''
        const market = rootState.frontend.countries.find(x => x.country === countryCode.toUpperCase())?.market
        const redirectSlug = market ? countryCode.toLowerCase() : $config.defaultMarketSlug
        if (!redirectSlug) {
          throw new Error('No redirectSlug is set, check your geoip settings and defaultMarketSlug setting in nuxt config')
        }
        const redirectTo = `/${redirectSlug}${req.originalUrl}`
        console.log(`Redirecting to ${redirectTo}`)
        await redirect(`${redirectTo}`)
      }
      await dispatch('dagmar/fetchCountryInformation')
    }
  }
}
