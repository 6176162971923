const getPricelistMarketSpecificValues = (store, product, marketID) => {
  const priceList = product.markets[marketID].pricesByPricelist[store.getters['frontend/currentPricelistId']]
  return {
    price: priceList.priceAsNumber,
    priceText: priceList.price,
    originalPrice: priceList.priceBeforeDiscountAsNumber,
    originalPriceText: priceList.priceBeforeDiscount,
    discount: priceList.discountPercent,
    isOnSale: priceList.discountPercent > 0,
    items: product.items.map(it => ({
      size: it.size,
      id: it.id,
      quantity: it.stockByMarket ? (it.stockByMarket[marketID] || 0) : 0,
      stockByMarket: it.stockByMarket
    }))
  }
}

const createSearchKey = (query, searchParameters, rankingIndex, marketId) => {
  const tempSearchParameters = { ...searchParameters }
  delete tempSearchParameters.page
  function sortObject (object) {
    const sortedObj = {}
    Object.keys(object).sort().forEach((key) => {
      sortedObj[key] = object[key]
    })
    return sortedObj
  }
  return rankingIndex + query + JSON.stringify(sortObject(tempSearchParameters)) + marketId
}

export default {
  namespaced: true,
  state () {
    return {
      autocomplete: [],
      currentAutocomplete: {},
      queries: [],
    }
  },

  mutations: {
    autocomplete (state, { query, result }) {
      const autocompleteObject = {
        query,
        result: {
          products: result.hits.map(p => p.id),
          totalProducts: result.nbHits,
          facets: result.facets
        }
      }
      state.currentAutocomplete = autocompleteObject
      state.autocomplete.push(autocompleteObject)
    },
    currentAutocomplete (state, autocompleteObject) {
      state.currentAutocomplete = autocompleteObject
    },
    queries (state, { query, searchParameters, rankingIndex, marketId, result }) {
      const queryObject = state.queries.find(x => x.key === createSearchKey(query, searchParameters, rankingIndex, marketId))
      if (!queryObject) {
        const transformedResult = {
          products: result.hits.map(p => p.id),
          totalProducts: result.nbHits,
          page: result.page,
          totalPages: result.nbPages,
          lookupFinished: true,
          facets: result.facets
        }
        state.queries.push({
          key: createSearchKey(query, searchParameters, rankingIndex, marketId),
          activeFilters: [],
          result: transformedResult,
          unfilteredResult: transformedResult
        })
      } else if (queryObject.result.page < result.page) {
        queryObject.unfilteredResult.products.push(...result.hits.map(p => p.id))
        queryObject.unfilteredResult.page = result.page
        queryObject.result = queryObject.unfilteredResult
        queryObject.activeFilters = []
      }
    },
    filterQuery (state, { query, searchParameters, rankingIndex, marketId, activeFilters, result }) {
      const queryObject = state.queries.find(x => x.key === createSearchKey(query, searchParameters, rankingIndex, marketId))
      if (queryObject) {
        queryObject.activeFilters = activeFilters
        queryObject.result = result
      }
    },
    removeFilterFromQuery (state, { query, searchParameters, rankingIndex, marketId }) {
      const queryObject = state.queries.find(x => x.key === createSearchKey(query, searchParameters, rankingIndex, marketId))
      if (queryObject) {
        queryObject.activeFilters = []
        queryObject.result = queryObject.unfilteredResult
      }
    },
  },

  actions: {
    async autocomplete ({ commit, dispatch, state, rootGetters }, query) {
      const autocomplete = state.autocomplete.find(x => x.query === query)
      if (autocomplete) {
        commit('currentAutocomplete', autocomplete)
      } else {
        const result = await this.$backendApi.get('/algolia/autocomplete-search', {
          params: {
            query,
            market_id: rootGetters['frontend/getMarketByCountryCode'](rootGetters['frontend/currentCountryCode'])
          }
        })
        result.data.hits.forEach((product) => {
          dispatch('product/addPartialProduct', product, { root: true })
        })
        commit('autocomplete', { query, result: result.data })
      }
    },
    async search ({ commit, dispatch, getters }, { query = '', searchParameters = {}, rankingIndex = '', marketId = undefined }) {
      const queryObject = getters.getQueryByKey(createSearchKey(query, searchParameters, rankingIndex, marketId))
      if (queryObject && queryObject.result.page >= (searchParameters.page ?? 0)) {
        if (queryObject.activeFilters.length > 0) {
          commit('removeFilterFromQuery', { query, searchParameters, rankingIndex, marketId })
          return getters.getQueryByKey(createSearchKey(query, searchParameters, rankingIndex, marketId))
        }
        return queryObject
      } else {
        const result = await this.$backendApi.get('/algolia/search', {
          params: {
            query,
            search_parameters: searchParameters,
            ranking_index: rankingIndex,
            market_id: marketId
          }
        })
        result.data.hits.forEach((product) => {
          dispatch('product/addPartialProduct', { ...product, ...getPricelistMarketSpecificValues(this, product, this.getters['frontend/currentMarketId']) }, { root: true })
        })
        commit('queries', { query, searchParameters, rankingIndex, marketId, result: result.data })
        return getters.getQueryByKey(createSearchKey(query, searchParameters, rankingIndex, marketId))
      }
    },

    async loadMore ({ dispatch, getters }, { query = '', searchParameters = {}, rankingIndex = '', marketId = undefined }) {
      const queryObject = getters.getQueryByKey(createSearchKey(query, searchParameters, rankingIndex, marketId))
      if (queryObject) {
        const newSearchParameters = { ...searchParameters, page: queryObject.result.page + 1 }
        if (queryObject.activeFilters.length > 0) {
          await dispatch('filter', { query, searchParameters: newSearchParameters, rankingIndex, marketId, activeFilters: queryObject.activeFilters })
        } else {
          await dispatch('search', { query, searchParameters: newSearchParameters, rankingIndex, marketId })
        }
      } else {
        await dispatch('search', { query, searchParameters, rankingIndex, marketId })
      }
    },

    resetFilters ({ commit }, { query = '', searchParameters = {}, rankingIndex = '', marketId = undefined }) {
      commit('removeFilterFromQuery', { query, searchParameters, rankingIndex, marketId })
    },

    async filter ({ commit, dispatch, getters }, { query = '', searchParameters = {}, rankingIndex = '', marketId = undefined, activeFilters = [] }) {
      if (activeFilters.length === 0) {
        commit('removeFilterFromQuery', { query, searchParameters, rankingIndex, marketId })
      } else {
        const newSearchParameters = { ...searchParameters }
        activeFilters.forEach((filter) => {
          newSearchParameters.filters = newSearchParameters.filters.length > 0 ? newSearchParameters.filters + ' AND ' : ''
          filter.values.forEach((value) => {
            newSearchParameters.filters += filter.key + ':"' + value + '" OR '
          })
          newSearchParameters.filters = newSearchParameters.filters.slice(0, -4)
        })
        const result = await dispatch('search', { query, searchParameters: newSearchParameters, rankingIndex, marketId })
        commit('filterQuery', { query, searchParameters, rankingIndex, marketId, activeFilters, result: result.result })
      }
      return getters.getQueryByKey(createSearchKey(query, searchParameters, rankingIndex, marketId))
    },

    async toggleFilter ({ dispatch, getters }, { query = '', searchParameters = {}, rankingIndex = '', marketId = undefined, filter }) {
      const queryObject = getters.getQueryByKey(createSearchKey(query, searchParameters, rankingIndex, marketId))
      const newActiveFilters = queryObject.activeFilters.map(x => ({ key: x.key, values: [...x.values] }))
      if (newActiveFilters.find(x => x.key === filter.key)) {
        const facet = newActiveFilters.find(x => x.key === filter.key)
        if (facet.values.find(x => x === filter.value)) {
          facet.values.splice(facet.values.findIndex(x => x === filter.value), 1)
          if (facet.values.length === 0) {
            newActiveFilters.splice(newActiveFilters.findIndex(x => x.key === filter.key), 1)
          }
        } else {
          facet.values.push(filter.value)
        }
      } else {
        newActiveFilters.push({ key: filter.key, values: [filter.value] })
      }
      return await dispatch('filter', { query, searchParameters: { ...searchParameters, page: 0 }, rankingIndex, marketId, activeFilters: newActiveFilters })
    },

    async clearFilterKey ({ dispatch, getters }, { query = '', searchParameters = {}, rankingIndex = '', marketId = undefined, filterKey }) {
      const queryObject = getters.getQueryByKey(createSearchKey(query, searchParameters, rankingIndex, marketId))
      const newActiveFilters = []
      queryObject.activeFilters.forEach((filter) => {
        if (filter.key !== filterKey) {
          newActiveFilters.push(filter)
        }
      })
      return await dispatch('filter', { query, searchParameters, rankingIndex, marketId, activeFilters: newActiveFilters })
    },
  },

  getters: {
    getCurrentAutocomplete: (state) => {
      return state.currentAutocomplete
    },
    getQueryByKey: state => (key) => {
      return state.queries.find(x => x.key === key)
    },
    getQueryResult: state => (query = '', searchParameters = {}, rankingIndex = '', marketId = undefined) => {
      const queryObject = state.queries.find(x => x.key === createSearchKey(query, searchParameters, rankingIndex, marketId))
      return queryObject?.result
    },
    getFacets: state => (query = '', searchParameters = {}, rankingIndex = '', marketId = undefined) => {
      const queryObject = state.queries.find(x => x.key === createSearchKey(query, searchParameters, rankingIndex, marketId))
      let facets = []
      if (queryObject) {
        facets = queryObject.unfilteredResult.facets.map((facet) => {
          return {
            key: facet.key,
            isActive: (queryObject.result.facets.find(x => x.key === facet.key)?.values?.length > 0 || false) || (queryObject.activeFilters.find(x => x.key === facet.key)?.values?.length > 0),
            selectedNum: queryObject.activeFilters.find(x => x.key === facet.key)?.values?.length || 0,
            values: facet.values.map((value) => {
              return {
                isActive: queryObject.result.facets.find(x => x.key === facet.key)?.values?.find(x => x.value === value.value)?.count > 0 || false,
                value: value.value,
                isSelected: !!queryObject.activeFilters.find(x => x.key === facet.key)?.values?.find(x => x === value.value),
                availableResults: queryObject.result.facets.find(x => x.key === facet.key)?.values?.find(x => x.value === value.value)?.count || 0
              }
            }).sort((a, b) => a.availableResults > b.availableResults ? -1 : 1)
          }
        })
      }
      return facets
    },
  }
}
