import { mapState } from 'vuex'
import IconCross from '~/assets/icons/CrossSmall.svg'

export default {
  name: 'Topbar',
  components: {
    IconCross
  },
  data () {
    return {
      showMessage: 0,
      closedTopBar: false
    }
  },
  computed: {
    ...mapState({
      countryInformation: state => state.dagmar.countryInformation
    }),
    enabled () {
      return this.countryInformation?.content?.topbarEnabled || false
    },
    texts () {
      return this.countryInformation?.content?.topbarTexts || []
    }
  },
  mounted () {
    this.fade(this.texts.length - 1)
  },
  beforeDestroy () {
    clearInterval(this.fadeInterval)
  },
  methods: {
    fade (length) {
      this.fadeInterval = setInterval(() => {
        if (this.showMessage < length) {
          this.showMessage = this.showMessage + 1
        } else {
          this.showMessage = 0
        }
      }, 5000)
    },
    closeTopBar () {
      this.closedTopBar = true
      this.$nextTick(() => {
        this.$store.dispatch('header/setHeaderHeight')
      })
    }
  }
}
