/**
 * Pricelists in centra have a name, id, currency formatting information and
 * a list over which country codes are allowed to use it
 */
export default {
  namespaced: true,
  state () {
    return {
      pricelists: [] // Should contains all pricelists
    }
  },
  mutations: {
    pricelists (state, pricelists) {
      state.pricelists = pricelists
    }
  },
}
