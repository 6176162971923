import { transformProduct } from '@/store/product'

/**
 * Because we have a differently structured routes from different external
 * systems and priorities between them together with a wildcard route, i found
 * it a good idea to create this store whose purpose is to have a mapping of
 * all externally defined routes and the main component to render with them.
 *
 * This store can then be mapped in pages/_.vue to conditionally render plain
 * content, products and categories.
 *
 * Special pages such as checkout are best placed in separate pages/checkout.vue
 * and similar, which have precedence over pages/_.vue.
 */
export default {
  state () {
    return {
      /**
       * An array of { route: 'something/foo/bar', component: 'storyblok' } that
       * can be used as a local lookup cache when finding what should be rendered
       *
       * The component key simply references a registered vue component
       */
      routes: [{
        route: '/api/cb/route/search',
        component: {
          component: 'search',
          id: null,
          url: 'search',
          data: null
        }
      },
      {
        route: '/search',
        component: {
          component: 'search',
          id: null,
          url: 'search',
          data: null
        }
      }],
      currentRoute: null
    }
  },
  mutations: {
    addRoute (state, payload) {
      state.routes = [payload].concat(
        state.routes.filter(route => route.route !== payload.route)
      )
      state.currentRoute = payload
    }
  },
  actions: {
    /**
     * Call the frackend to get a component name which can be renderered depending
     * on an external route table (or simply 404)
     */
    async lookupRoute ({ dispatch, commit, rootGetters, state, rootState }, fullPath) {
      const route = fullPath.replace(/\/[\w-]+\/?/, '/')

      // If we have the route in state already we don't need a new lookup
      const found = state.routes.find(x => x.route === (route === '/search' ? route : fullPath))
      if (found) {
        commit('addRoute', found)
        return Promise.resolve(found.component)
      }

      const urlProduct = rootGetters['product/getFullProductByUrl'](route.slice(1))
      if (urlProduct) {
        const payload = {
          route: fullPath,
          component: {
            component: 'product',
            id: urlProduct.id,
            url: fullPath,
            data: urlProduct
          }
        }
        commit('addRoute', payload)
        return Promise.resolve(payload.component)
      }

      const language = rootState.frontend.currentLanguageCode
      try {
        const result = await this.$backendApi.get(`route/${language || ''}`, { params: { route } })
        if (result.data.component) {
          const payload = {
            route: fullPath,
            component: result.data
          }
          payload.component.url = fullPath
          if (payload.component.component === 'product') {
            const product = transformProduct(this, payload.component.data)
            dispatch('product/addPartialProduct', product, { root: true })
            payload.component.data = product
          } else if (payload.component.component === 'storyblok') {
            dispatch('storyblok/addStory', payload.component.data, { root: true })
          }
          commit('addRoute', payload)
          return Promise.resolve(payload.component)
        }
      } catch (e) {
        return Promise.resolve(undefined)
      }

      return Promise.resolve(undefined)
    },
  }
}
