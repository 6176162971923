export default {
  state () {
    return {
      transparentNavbar: false,
      whiteNavbarText: false,
      panelIsOpen: false,
      headerHeight: 64
    }
  },
  mutations: {
    setTransparentNavbar (state, value) {
      state.transparentNavbar = value
    },
    setWhiteNavbarText (state, value) {
      state.whiteNavbarText = value
    },
    setPanelIsOpen (state, value) {
      state.panelIsOpen = value
    },
    setHeaderHeight (state, value) {
      state.headerHeight = value
    }
  },
  actions: {
    setTransparentNavbar ({ commit }, value) {
      commit('setTransparentNavbar', value)
    },
    setWhiteNavbarText ({ commit }, value) {
      commit('setWhiteNavbarText', value)
    },
    setPanelIsOpen ({ commit }, value) {
      commit('setPanelIsOpen', value)
    },
    setHeaderHeight ({ commit }, value) {
      if (document.getElementById('header')?.clientHeight) {
        commit('setHeaderHeight', document.getElementById('header').clientHeight)
      }
    }
  },
  getters: {
    headerHeight: state => state.headerHeight
  }
}
