export default {
  namespaced: true,
  state () {
    return {
      mobileSidebarOpen: false,
      desktopSubmenuOpen: false,
      miniCartOpen: false,
      searchOpen: false,
      searchAutocompleteResult: null,
      countrySelectorOpen: false,
      mobileProductFilterOpen: false,
      mobileProductFilterV2Open: false,
      enableDiscountFilter: false,
      currentQueryName: null,
      filterObject: {},

      /**
       * Overlay
       * Hidden if set to 0
       * The header has a z-index of 10, so set this to either
       * above or below 10 depending on use case
       */
      overlayIndex: 0,
    }
  },
  mutations: {
    /**
     * Mobile Sidebar
     */
    showMobileSidebar (state) {
      // Close everything else
      state.searchOpen = false
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileProductFilterOpen = false
      // Open sidebar
      state.mobileSidebarOpen = true
      state.overlayIndex = 11
    },
    hideMobileSidebar (state) {
      state.mobileSidebarOpen = false
      state.overlayIndex = 0
    },

    /**
     * Desktop Submenu
     */
    showDesktopSubmenu (state) {
      // Close everything else
      state.searchOpen = false
      state.countrySelectorOpen = false
      state.mobileSidebarOpen = false
      state.miniCartOpen = false
      state.mobileProductFilterOpen = false
      // Open submenu
      state.desktopSubmenuOpen = true
      state.overlayIndex = 9
    },
    hideDesktopSubmenu (state) {
      state.desktopSubmenuOpen = false
      state.overlayIndex = 0
    },

    /**
     * Minicart
     */
    showMiniCart (state) {
      // Close everything else
      state.searchOpen = false
      state.countrySelectorOpen = false
      state.mobileSidebarOpen = false
      state.desktopSubmenuOpen = false
      state.mobileProductFilterOpen = false
      // Open minicart
      state.miniCartOpen = true
      state.overlayIndex = 9
    },
    hideMiniCart (state) {
      state.miniCartOpen = false
      state.overlayIndex = 0
    },

    /**
     * Search
     */
    showSearch (state) {
      // Close everything else
      state.miniCartOpen = false
      state.countrySelectorOpen = false
      state.mobileSidebarOpen = false
      state.desktopSubmenuOpen = false
      state.mobileProductFilterOpen = false
      // Open search
      state.searchOpen = true
      state.overlayIndex = 8
    },
    hideSearch (state) {
      state.searchOpen = false
      state.overlayIndex = 0
    },

    /**
     * Country Selector
     */
    showCountrySelector (state) {
      // Close everything else
      state.miniCartOpen = false
      state.searchOpen = false
      state.mobileSidebarOpen = false
      state.desktopSubmenuOpen = false
      state.mobileProductFilterOpen = false
      // Open country selector
      state.countrySelectorOpen = true
      state.overlayIndex = 11
    },
    hideCountrySelector (state) {
      state.countrySelectorOpen = false
      state.overlayIndex = 0
    },

    /**
     * Mobile Product Filter Sidebar
     */
    showMobileProductFilter (state, { queryName, enableDiscountFilter }) {
      // Every time we show the filter menu we replace what's inside it, THAT'S THE ONLY WAY OK!
      state.currentQueryName = queryName
      state.enableDiscountFilter = enableDiscountFilter

      // Close everything else
      state.searchOpen = false
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.mobileProductFilterV2Open = false
      // Open filter
      state.mobileProductFilterOpen = true
      state.overlayIndex = 11
    },
    hideMobileProductFilter (state) {
      state.mobileProductFilterOpen = false
      state.overlayIndex = 0
    },
    showMobileProductFilterV2 (state, filterObject) {
      // Every time we show the filter menu we replace what's inside it, THAT'S THE ONLY WAY OK!
      state.filterObject = filterObject

      // Close everything else
      state.searchOpen = false
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.mobileProductFilterOpen = false
      // Open filter
      state.mobileProductFilterV2Open = true
      state.overlayIndex = 11
    },

    /**
     * Close All
     */
    closeAll (state) {
      state.miniCartOpen = false
      state.searchOpen = false
      state.mobileSidebarOpen = false
      state.desktopSubmenuOpen = false
      state.countrySelectorOpen = false
      state.mobileProductFilterOpen = false
      state.mobileProductFilterV2Open = false
      state.overlayIndex = 0
    },

    searchAutocompleteResult (state, result) {
      state.searchAutocompleteResult = result
    },
  },
  actions: {
    setSearchAutocompleteResult ({ commit }, result) {
      commit('searchAutocompleteResult', result)
    },
    showMobileSidebar ({ commit }) {
      commit('showMobileSidebar')
    },
    hideMobileSidebar ({ commit }) {
      commit('hideMobileSidebar')
    },
    showDesktopSubmenu ({ commit }) {
      commit('showDesktopSubmenu')
    },
    hideDesktopSubmenu ({ commit, state }) {
      if (state.desktopSubmenuOpen) {
        commit('hideDesktopSubmenu')
      }
    },
    toggleMiniCart ({ commit, state }) {
      if (!state.miniCartOpen) {
        commit('showMiniCart')
      } else {
        commit('hideMiniCart')
      }
    },
    hideMiniCart ({ commit }) {
      commit('hideMiniCart')
    },
    showSearch ({ commit }) {
      commit('showSearch')
    },
    hideSearch ({ commit }) {
      commit('hideSearch')
    },
    showCountrySelector ({ commit }) {
      commit('showCountrySelector')
    },
    hideCountrySelector ({ commit }) {
      commit('hideCountrySelector')
    },
    showMobileProductFilter ({ commit }, payload) {
      commit('showMobileProductFilter', payload)
    },
    showMobileProductFilterV2 ({ commit }, payload) {
      commit('showMobileProductFilterV2', payload)
    },
    hideMobileProductFilter ({ commit }) {
      commit('hideMobileProductFilter')
    },
    closeAll ({ commit }) {
      commit('closeAll')
    },
  }
}
