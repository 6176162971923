import { mapGetters } from 'vuex'

export default {

  props: {
    showAccountMenu: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      accountMenuVisible: false
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'member/loggedIn'
    })
  },

  methods: {
    logOut () {
      this.$store.dispatch('member/logout')
    }
  }
}
