import Vue from 'vue'

// MP Components
Vue.component('MpLink', () => import('@/components/mp-link/MpLink.vue'))
Vue.component('ProductListController', () => import('@/components/product-list-controller/ProductListController.vue'))

// Local
Vue.component('MadeButton', () => import('~/components/made-button/MadeButton.vue'))
Vue.component('Breadcrumbs', () => import('~/components/breadcrumbs/Breadcrumbs.vue'))
Vue.component('MiniCart', () => import('~/components/mini-cart/MiniCart.vue'))
Vue.component('CartItem', () => import('~/components/mini-cart/CartItem.vue'))
Vue.component('Grid', () => import('~/components/grid/Grid.vue'))
Vue.component('Headline', () => import('~/components/headline/Headline.vue'))
Vue.component('Hero', () => import('~/components/hero/Hero.vue'))
Vue.component('ProductSizeSelector', () => import('~/components/product-size-selector/ProductSizeSelector.vue'))
Vue.component('SubNav', () => import('~/components/navbar/SubNav.vue'))
Vue.component('Newsletter', () => import('~/components/newsletter/Newsletter.vue'))
Vue.component('Panel', () => import('~/components/panel/Panel.vue'))
Vue.component('ProductCard', () => import('~/components/product-card/ProductCard.vue'))
Vue.component('ProductDescription', () => import('~/components/product-description/ProductDescription.vue'))
Vue.component('ProductDetails', () => import('~/components/product-details/ProductDetails.vue'))
Vue.component('ProductImageGallery', () => import('~/components/product-image-gallery/ProductImageGallery.vue'))
Vue.component('ProductImageZoom', () => import('~/components/product-image-zoom/ProductImageZoom.vue'))
Vue.component('ProductList', () => import('~/components/product-list/ProductList.vue'))
Vue.component('ProductListSwipeable', () => import('~/components/product-list-swipeable/ProductListSwipeable.vue'))
Vue.component('ProductListSwipeableController', () => import('~/components/product-list-swipeable/ProductListSwipeableController.vue'))
Vue.component('ProductPrice', () => import('~/components/product-price/ProductPrice.vue'))
Vue.component('ProductSlider', () => import('~/components/product-slider/ProductSlider.vue'))
Vue.component('ProductVariants', () => import('~/components/product-variants/ProductVariants.vue'))
Vue.component('ResponsiveImage', () => import('~/components/responsive-image/ResponsiveImage.vue'))
Vue.component('TextBlock', () => import('~/components/textblock/TextBlock.vue'))
Vue.component('ImageBlock', () => import('~/components/image-block/ImageBlock.vue'))
Vue.component('GridColumn', () => import('~/components/grid-column/GridColumn.vue'))
Vue.component('CountrySelectorSidebar', () => import('~/components/country-selector-sidebar/CountrySelectorSidebar.vue'))
Vue.component('CountrySelector', () => import('~/components/country-selector/CountrySelector.vue'))
Vue.component('SizeGuideSidebar', () => import('~/components/size-guide-sidebar/SizeGuideSidebar.vue'))
Vue.component('TransitionExpand', () => import('~/components/transition-expand/TransitionExpand.vue'))
Vue.component('Discount', () => import('~/components/discount/Discount.vue'))
Vue.component('Assistance', () => import('~/components/assistance/Assistance.vue'))
Vue.component('QuickLinks', () => import('~/components/quick-links/QuickLinks.vue'))
Vue.component('ProductCardHover', () => import('~/components/product-card-hover/ProductCardHover.vue'))
Vue.component('NotifyMeSidebar', () => import('~/components/notify-me-sidebar/NotifyMeSidebar.vue'))
Vue.component('SpacerBlock', () => import('~/components/spacer-block/SpacerBlock.vue'))

// Main views
/* eslint-disable */
Vue.component('storyblok', () => import('~/views/Storyblok.vue'))
Vue.component('product', () => import('~/views/Product.vue'))
Vue.component('search', () => import('~/views/Search.vue'))
/* eslint-enable */
