function sendToSentry (app, inLevel, errorName, se) {
  const outLevel = (se.message === 'Network Error' || se?.cause?.message === 'Network Error' || se?.cause?.message === 'Request aborted' || /timeout of \d+ms exceeded/.test(se?.cause?.message) || /timeout of \d+ms exceeded/.test(se?.message)) ? 'info' : inLevel
  se.name = errorName
  console.debug(outLevel, se)
  app.$sentry.withScope((scope) => {
    scope.setLevel(outLevel)
    app.$sentry.captureException(se)
  })
}

export default ({ app }, inject) => {
  const sentryMiddleware = {
    captureFatal (errorName, errorText, cause = undefined) {
      sendToSentry(app, 'fatal', errorName, errorText, cause)
    },
    captureError (errorName, errorText, cause = undefined) {
      sendToSentry(app, 'error', errorName, errorText, cause)
    },
    captureWarning (errorName, errorText, cause = undefined) {
      sendToSentry(app, 'warning', errorName, errorText, cause)
    },
    captureInfo (errorName, errorText, cause = undefined) {
      sendToSentry(app, 'info', errorName, errorText, cause)
    },
    captureDebug (errorName, errorText, cause = undefined) {
      sendToSentry(app, 'debug', errorName, errorText, cause)
    },
  }

  inject('sentryMiddleware', sentryMiddleware)
}
