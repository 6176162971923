import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CheckoutFooter',
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings'
    }),
    ...mapState({
      countryInformation: state => state.dagmar.countryInformation
    }),
    paymentLogos () {
      return this.countryInformation?.content?.checkoutFlowLogos || ''
    },
    email () {
      return this.countryInformation?.content?.assistanceEmail || ''
    },
    links () {
      return this.countryInformation?.content?.assistanceLinks || ''
    },
    showClientService () {
      const date = new Date(new Date(Date.now()).toLocaleString('en-US', { timeZone: 'Europe/Stockholm' }))
      return this.settings.customer_service_open_days.includes(date.getDay().toString()) &&
        Number(this.settings.customer_service_open_hour) <= date.getHours() &&
        Number(this.settings.customer_service_closing_hour) >= date.getHours()
    }
  },
  methods: {
    sendEvent (contentType, contentSubType, itemID, options) {
      this.$analytics.selectContent(contentType, contentSubType, itemID, options)
    }
  }
}
