import { mapGetters } from 'vuex'
import ISOCountries from '@/isoCountries'
import ChevronIcon from '~/assets/icons/ChevronDownMini.svg'

export default {
  components: {
    ChevronIcon
  },
  props: {
    focusOnMount: {
      type: Boolean,
      default: false
    },
    headlineSize: {
      type: String,
      default: 'sm'
    },
    headline: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    redirectUrl: {
      type: String,
      default: null
    },
    secretPage: {
      type: Boolean,
      default: false
    },
    fullVersion: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      countryCode: this.$store.getters['frontend/currentCountryCode'],
      registerFirstName: '',
      registerLastName: '',
      registerEmail: '',
      registerNewsletter: false,
      registerPassword: '',
      termsAndConditions: false,
      accountEmailExists: false,
      alreadyRegistered: false,
      resetEmailSent: false,
      errorMessages: {}
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings'
    }),
    countryName () {
      return ISOCountries[this.countryCode.toUpperCase()]
    },
    countries () {
      return ISOCountries
    },
    selection () {
      return this.$store.state['centra-cart'].selection ? this.$store.state['centra-cart'].selection.selection : null
    },
    years () {
      const currentYear = new Date().getFullYear()
      return Array.from({ length: 100 }, (_, i) => currentYear - 18 - i)
    },
    months () {
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    },
    days () {
      return Array.from({ length: 31 }, (_, i) => i + 1)
    },
    resetEmailSentText () {
      return this.settings?.account_register_reset_password_email_text?.html || ''
    },
    uspText () {
      return this.settings?.account_register_usp_text?.html || ''
    },
    termsAndConditionsText () {
      return this.settings?.account_register_terms_and_condition_text?.html || ''
    },
  },

  methods: {
    validateEmail (email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },
    nl2br (str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },

    activateSection (section) {
      if (section === 'login') {
        this.$emit('activateSection', 'login')
        this.$analytics.selectContent('account', 'register', 'goToLogin', undefined)
      }
      if (section === 'recover') {
        this.$emit('activateSection', 'recover')
        this.$analytics.selectContent('account', 'login', 'goToRecover', undefined)
      }
    },

    validateForm () {
      if (!this.registerFirstName) {
        this.$set(this.errorMessages, 'firstName', 'Please enter your first name')
      }
      if (!this.registerLastName) {
        this.$set(this.errorMessages, 'lastName', 'Please enter your last name')
      }
      if (!this.registerEmail) {
        this.$set(this.errorMessages, 'email', 'Please enter your email')
      } else if (!this.validateEmail(this.registerEmail)) {
        this.$set(this.errorMessages, 'email', 'Please enter a valid email')
      }
      if (!this.registerPassword) {
        this.$set(this.errorMessages, 'password', 'Please enter a password')
      } else if (this.registerPassword.length < 8) {
        this.$set(this.errorMessages, 'password', 'Password must be at least 8 characters long')
      }
      if (!this.termsAndConditions) {
        this.$set(this.errorMessages, 'termsAndConditions', 'Please accept the terms and conditions')
      }
      if (this.birthYear || this.birthMonth || this.birthDay) {
        if (!this.birthYear) {
          this.$set(this.errorMessages, 'birthdate', 'Please enter your birthdate')
        }
        if (!this.birthMonth) {
          this.$set(this.errorMessages, 'birthdate', 'Please enter your birthdate')
        }
        if (!this.birthDay) {
          this.$set(this.errorMessages, 'birthdate', 'Please enter your birthdate')
        }
      }
      if (!this.countryCode) {
        this.$set(this.errorMessages, 'country', 'Please select your country')
      }
      return !Object.keys(this.errorMessages).length > 0
    },
    register () {
      this.errorMessages = {}
      if (!this.validateForm()) {
        return
      }
      const registerData = {
        firstName: this.registerFirstName,
        lastName: this.registerLastName,
        email: this.registerEmail,
        password: this.registerPassword,
        country: this.countryCode,
        birthdate: this.birthYear && this.birthMonth && this.birthDay ? this.birthYear + (this.birthMonth < 10 ? '-0' : '-') + this.birthMonth + (this.birthDay < 10 ? '-0' : '-') + this.birthDay : undefined,
        consents: [
          {
            key: 'register',
            consented: true
          }
        ]
      }

      this.$store.dispatch('member/registerMember', registerData).then((response) => {
        if (response.data.errors?.length > 1) {
          if (response.data.errors.email) {
            this.alreadyRegistered = true
            this.$set(this.errorMessages, 'email', response.data.errors.email)
          }
          if (response.data.errors.password) {
            this.$set(this.errorMessages, 'password', response.data.errors.password)
          }
        } else if (response.register) {
          this.$emit('loggedIn')
          this.$emit('loggingIn')

          if (!this.secretPage) {
            this.$router.push(this.redirectUrl ? this.$u(this.redirectUrl) : this.$u('account'))
          }
          this.$analytics.signUp('login')
        } else if (response.resetPassword) {
          this.resetEmailSent = true
        }
      })
      this.$analytics.selectContent('account', 'register', 'tryRegister', undefined)
    }
  }
}
