let baseUrl = ''
const defaultAttr = {
  fit: 'scale-down',
  format: 'auto',
  metadata: 'copyright',
  onerror: 'redirect',
  quality: 70
}

export default ({ $config, store }, inject) => {
  inject('imgproxy', {
    transform: (url, definition) => {
      useBaseUrl($config.imageProxyUrl)
      return transform(url, definition)
    }
  })

  function transform (url, attr) {
    if (url === undefined || url === null) {
      return null
    }

    // Fix for storyblok
    url = url.replace(/^\/\/a\./, '//img2.')
    url = url.replace(/^\/\//, 'https://')

    // Image provider Cloudflare
    if ($config.imageProxyProvider?.toUpperCase() === 'CLOUDFLARE') {
      return processImgproxyCloudflare(url, attr)
    }

    // TODO All below is legacy, can be removed after migration and removal of internal imgproxy
    // Image provider not Cloudflare
    return processImgproxy(url, attr)
  }

  function processImgproxyCloudflare (url, attr) {
    const options = (attr.anim || defaultAttr.anim ? 'anim=' + attr.anim || defaultAttr.anim + ',' : '') +
      (attr.background || defaultAttr.background ? 'background=' + (attr.background || defaultAttr.background) + ',' : '') +
      (attr.blur || defaultAttr.blur ? 'blur=' + (attr.blur || defaultAttr.blur) + ',' : '') +
      (attr.border || defaultAttr.border ? 'border=' + (attr.border || defaultAttr.border) + ',' : '') +
      (attr.brightness || defaultAttr.brightness ? 'brightness=' + (attr.brightness || defaultAttr.brightness) + ',' : '') +
      (attr.compression || defaultAttr.compression ? 'compression=' + (attr.compression || defaultAttr.compression) + ',' : '') +
      (attr.contrast || defaultAttr.contrast ? 'contrast=' + (attr.contrast || defaultAttr.contrast) + ',' : '') +
      (attr.dpr || defaultAttr.dpr ? 'dpr=' + (attr.dpr || defaultAttr.dpr) + ',' : '') +
      (attr.fit || defaultAttr.fit ? 'fit=' + (attr.fit || defaultAttr.fit) + ',' : '') +
      (attr.format || defaultAttr.format ? 'format=' + (attr.format || defaultAttr.format) + ',' : '') +
      (attr.gamma || defaultAttr.gamma ? 'gamma=' + (attr.gamma || defaultAttr.gamma) + ',' : '') +
      (attr.gravity || defaultAttr.gravity ? 'gravity=' + (attr.gravity || defaultAttr.gravity) + ',' : '') +
      (attr.height || defaultAttr.height ? 'height=' + (attr.height || defaultAttr.height) + ',' : '') +
      (attr.metadata || defaultAttr.metadata ? 'metadata=' + (attr.metadata || defaultAttr.metadata) + ',' : '') +
      (attr.onerror || defaultAttr.onerror ? 'onerror=' + (attr.onerror || defaultAttr.onerror) + ',' : '') +
      (attr.quality || defaultAttr.quality ? 'quality=' + (attr.quality || defaultAttr.quality) + ',' : '') +
      (attr.rotate || defaultAttr.rotate ? 'rotate=' + (attr.rotate || defaultAttr.rotate) + ',' : '') +
      (attr.sharpen || defaultAttr.sharpen ? 'sharpen=' + (attr.sharpen || defaultAttr.sharpen) + ',' : '') +
      (attr.trim || defaultAttr.trim ? 'trim=' + (attr.trim || defaultAttr.trim) + ',' : '') +
      (attr.width || defaultAttr.width ? 'width=' + (attr.width || defaultAttr.width) + ',' : '')

    return `${baseUrl}/${options.substring(0, options.length - 1)}/${url}`
  }

  function processImgproxy (url, attr) {
    let width = 'preset:sharp/resize:fit:' + attr.width
    if (attr.height) {
      width += ':' + attr.height
    }
    const quality = attr.quality ? attr.quality : '70'
    let def = `${width}/gravity:nowe/quality:${quality}`
    if ($config.forceJpg) {
      def += '.jpg'
    }
    def = stripSlashes(def)
    url = stripSlashes(url)
    const b64 = Buffer.from(url).toString('base64')
    return `${baseUrl}/${def}/${b64}`
  }
}

function useBaseUrl (url) {
  baseUrl = stripSlashes(url)
}

function stripSlashes (text) {
  return text.replace(/^\/+|\/+$/g, '')
}
