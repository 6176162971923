import { mapGetters } from 'vuex'
import IconCross from '~/assets/icons/CrossLarge.svg'

export default {
  name: 'NewsletterPopUp',
  components: { IconCross },
  props: {
    currentRoute: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      showNewsletterPopUp: false,
      seenBefore: true,
      pageTimer: null
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings',
      loggedIn: 'member/loggedIn',
      currentCountry: 'frontend/currentCountry'
    }),
  },
  watch: {
    pageTimer: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.pageTimer--
          }, 1000)
        } else if (!this.seenBefore) {
          this.showNewsletterPopUp = true
          this.seenBefore = true
        }
      },
      immediate: true
    },
    currentRoute: {
      handler (value) {
        if (!this.seenBefore) {
          this.pageTimer = this.settings?.newsletterPopUpTimer
        }
      },
      deep: true
    }
  },
  mounted () {
    const cookie = this.$cookies.get('_hod_s')
    this.seenBefore = !!cookie?.npu
    if (this.loggedIn || !this.settings?.newsletterPopUpTimer || this.settings?.newsletterPopUpTimer === 0 || !this.settings?.newsletterPopUpCountries?.map(country => country?.toLowerCase())?.includes(this.currentCountry?.country?.toLowerCase())) {
      this.seenBefore = true
    } else {
      this.pageTimer = this.settings?.newsletterPopUpTimer
    }
  },
  methods: {
    hidePopUp () {
      this.$analytics.setHodGa4Cookie({ npu: Date.now() })
      this.showNewsletterPopUp = false
    },
    subscribed () {
      this.$analytics.setHodGa4Cookie({ npus: true })
    }
  }
}
