export default {
  name: 'FilterOption',
  props: {
    label: {
      type: String,
      default: ''
    },
    swatch: {
      type: String,
      default: ''
    },
    availableResults: {
      type: Number,
      default: 0
    },
    optionSelected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandler () {
      this.$emit('click')
    }
  }
}
