import IconCross from 'assets/icons/CrossLarge.svg'

export default {
  name: 'CountryPickerPopUp',
  components: {
    IconCross
  },
  props: {
    countries: {
      type: Array,
      required: true
    },
    currentCountryCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showPopUp: false,
      ipCountry: {}
    }
  },
  computed: {
    currentCountry () {
      return this.countries.find(country => this.currentCountryCode?.toLowerCase() === country?.country?.toLowerCase())
    },
  },
  async mounted () {
    try {
      const ipCountryRes = await this.$backendApi.get('/geoip/')
      const ipCountry = ipCountryRes.data
      const cookie = this.$cookies.get('_hod_country')
      this.ipCountry = this.countries.find(country => ipCountry?.toLowerCase() === country.country.toLowerCase())
      this.showPopUp = (ipCountry?.toLowerCase() !== this.currentCountryCode.toLowerCase() && this.ipCountry && this.currentCountryCode.toLowerCase() !== cookie?.toLowerCase())
    } catch (e) {
      this.$sentryMiddleware.captureFatal('Backend Bad Response', new Error('Backend Bad Response', { cause: e }))
    }
  },
  methods: {
    hidePopUp () {
      this.showPopUp = false
      this.$cookies.set('_hod_country', this.currentCountryCode, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        sameSite: 'lax'
      })
    },
    async changeCountry () {
      this.$cookies.set('_hod_country', this.ipCountry.country, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        sameSite: 'lax'
      })
      await this.$store.dispatch('frontend/setCurrentCountryCode', this.ipCountry.country)
      const slugParts = this.$route.path.split('/')
      slugParts[1] = this.ipCountry.country.toLowerCase()
      window.location.href = slugParts.join('/')
    }
  }
}
