import Vue from 'vue'
import StoryblokVue from 'storyblok-vue'
Vue.use(StoryblokVue)

/**
 * Call it storyapi so we can hot replace the storyblok-nuxt module with our
 * own that uses an express proxy which also handles cache
 *
 * @param context
 * @param inject
 */
export default (context, inject) => {
  if (process.client && context.route.query._storyblok) {
    // Inject the bridge (used for live preview inside of Storyblok) only client-side
    const storybridge = {
      proxy: null,
      eventsForProxy: [],
      on: function (events, callback) {
        // console.log('plugion on')
        if (!window.storyblok || !this.proxy) {
          // Cache the events and pop them when it's actually loaded
          this.eventsForProxy.push({ events, callback })
        } else {
          this.proxyOn(events, callback)
        }
      },
      proxyOn: function (events, callback) {
        // console.log('plugin proxyOn')
        window.storyblok.on(events, (event) => {
          if (event.action === 'input') {
            event.story.content = this.proxy.addComments(event.story.content, event.story.id)
          }
          callback(event)
        })
      },
      load: function () {
        // console.log('plugin load')
        const instance = this
        if (!document.getElementById('storyblok-javascript-bridge')) {
          const script = document.createElement('script')
          script.async = true
          script.src = `https://app.storyblok.com/f/storyblok-latest.js?t=${context.$config.storyblok.token}`
          script.id = 'storyblok-javascript-bridge'
          script.onload = function () {
            instance.proxy = window.storyblok
            while (instance.eventsForProxy.length) {
              const list = instance.eventsForProxy.pop()
              instance.proxyOn(list.events, list.callback)
            }
          }
          document.getElementsByTagName('head')[0].appendChild(script)
        }
      }
    }

    inject('storybridge', storybridge)
    if (!Vue._storybridgeLoaded) {
      Vue._storybridgeLoaded = true
      storybridge.load()
    }
  }
}
