export const baseAnalyticsTool = ({ $config, store, route }, abTests) => {
  return {
    accountCheckout: () => {
    },
    addPaymentInfo: (cart, paymentMethod = '') => {
    },
    addShippingInfo: (cart, shippingMethod) => {
    },
    addToCart: (item, quantity) => {
    },
    beginCheckout: (cart) => {
    },
    consentGiven: () => {
    },
    login: () => {
    },
    purchase: (order) => {
    },
    removeFromCart: (item, quantity) => {
    },
    search: (query) => {
    },
    selectContent: (contentType, contentSubType, itemID, options) => {
    },
    selectItem: (product, list, position) => {
    },
    signUp: (type) => {
    },
    viewCart: (cart) => {
    },
    viewItem: (product) => {
    },
    viewItemList: (product, list, position) => {
    },
    pageView: (pageType, pageTitle, pageSubType, pageLastUpdate = 0) => {
    },
    userProperties: (data) => {
    },
    croTestApplied: (testName, testVersion, formChoice = undefined) => {
    },
    croTestConversion: (testName, conversionNumber, formChoice = undefined) => {
    },
    sendErrorEvent: (errorCode, errorMessage) => {
    }
  }
}
