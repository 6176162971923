export default {
  state () {
    return {
      notifyMeSidebarOpen: false,
      product: null,
      item: null
    }
  },

  mutations: {
    showNotifyMeSidebar (state) {
      state.notifyMeSidebarOpen = true
    },

    hideNotifyMeSidebar (state) {
      state.notifyMeSidebarOpen = false
    },

    setProductInfo (state, { product, item }) {
      state.product = product
      state.item = item
    },

    resetProductInfo (state) {
      state.product = null
      state.item = null
    }
  },

  actions: {
    showNotifyMeSidebar ({ dispatch, commit }, { product, item }) {
      commit('setProductInfo', { product, item })
      commit('showNotifyMeSidebar')

      return dispatch('ui/closeAll', null, { root: true })
    },

    hideNotifyMeSidebar ({ commit }) {
      commit('hideNotifyMeSidebar')
      commit('resetProductInfo')
    }
  }
}
