/**
 * All Analytics events goes through here
 * @param $config
 * @param store vuex store
 * @param inject
 */

import crypto from 'crypto'
import { gtmGa4Tool } from '@/utils/gtm-ga4-analytics'

const tools = [gtmGa4Tool]

const abTests = [
]

export default ({ $config, store, route }, inject) => {
  inject('analytics', {
    accountCheckout: () => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).accountCheckout()
      })
    },
    addPaymentInfo: (cart, paymentMethod = '') => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).addPaymentInfo(cart, paymentMethod)
      })
    },
    addShippingInfo: (cart, shippingMethod) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).addShippingInfo(cart, shippingMethod)
      })
    },
    addToCart: (item, quantity) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).addToCart(item, quantity)
      })
    },
    beginCheckout: (cart) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).beginCheckout(cart)
      })
    },
    consentGiven: () => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).consentGiven()
      })
    },
    login: () => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).login()
      })
    },
    purchase: (order) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).purchase(order)
      })
    },
    removeFromCart: (item, quantity) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).removeFromCart(item, quantity)
      })
    },
    search: (query) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).search(query)
      })
    },
    /**
     * An event for all contents that gets a click, see different options parameters to choose from
     * @param contentType
     * @param contentSubType
     * @param itemID
     * @param options [position, product_name, form_name, form_choice, video, image]
     */
    selectContent: (contentType, contentSubType, itemID, options) => {
      hodGa4Cookie(store)
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).selectContent(contentType, contentSubType, itemID, options)
      })
    },
    selectItem: (product, list, position) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).selectItem(product, list, position)
      })
    },
    signUp: (type) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).signUp(type)
      })
    },
    viewCart: (cart) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).viewCart(cart)
      })
    },
    viewItem: (product) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).viewItem(product)
      })
    },
    viewItemList: (product, list, position) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).viewItemList(product, list, position)
      })
    },
    pageView: (pageType, pageTitle, pageSubType, pageLastUpdate = 0) => {
      hodGa4Cookie(store)
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).pageView(pageType, pageTitle, pageSubType, pageLastUpdate)
      })
    },
    /**
     * @param data should be structured accordingly
     * {
     *         user_id,
     *         email_address,
     *         phone_number,
     *         first_name,
     *         last_name,
     *         city,
     *         state,
     *         postal_code,
     *         country
     *       }
     */
    userProperties: (data) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).userProperties(data)
      })
      const cookieConsent = store.$cookies.get('_hod_consent')
      if (cookieConsent?.analytics_storage) {
        const cookie = store.$cookies.get('_hod_g')
        const payload = {
          a: data.user_id || (cookie?.a || undefined),
          b: data.user_id ? crypto.createHash('sha256').update(data.user_id).digest('hex') : (cookie?.b || undefined),
          c: data.email_address ? crypto.createHash('sha256').update(data.email_address.toLowerCase()).digest('hex') : (cookie?.c || undefined),
          d: data.phone_number ? ((data.phone_number.replace(/\D/g, '').slice(0, 2) !== '46' && data.country === 'SE') ? crypto.createHash('sha256').update('46' + data.phone_number.replace(/\D/g, '').slice(1)).digest('hex') : crypto.createHash('sha256').update(data.phone_number.replace(/\D/g, '')).digest('hex')) : (cookie?.d || undefined),
          e: data.first_name ? crypto.createHash('sha256').update(data.first_name.toLowerCase()).digest('hex') : (cookie?.e || undefined),
          f: data.last_name ? crypto.createHash('sha256').update(data.last_name.toLowerCase()).digest('hex') : (cookie?.f || undefined),
          g: data.city ? crypto.createHash('sha256').update(data.address.toLowerCase()).digest('hex') : (cookie?.g || undefined),
          h: data.city ? crypto.createHash('sha256').update(data.city.toLowerCase()).digest('hex') : (cookie?.h || undefined),
          i: data.state ? crypto.createHash('sha256').update(data.state.toLowerCase()).digest('hex') : (cookie?.i || undefined),
          j: data.postal_code ? crypto.createHash('sha256').update(data.postal_code.toLowerCase()).digest('hex') : (cookie?.j || undefined),
          k: data.country ? crypto.createHash('sha256').update(data.country.toLowerCase()).digest('hex') : (cookie?.k || undefined)
        }
        store.$cookies.set('_hod_g', payload, {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
          sameSite: 'lax'
        })
      }
      hodGa4Cookie(store, data)
    },
    setHodGa4Cookie: (data) => {
      hodGa4Cookie(store, data)
    },
    isInAbTest: (testName) => {
      const abTest = abTests.find(({ name }) => name === testName)
      if (abTest && abTest.startDate <= Date.now() && Date.now() <= abTest.endDate) {
        if (process.server) {
          return parseInt(route.query?.abtestid) === abTest.id ? (parseInt(route.query?.abtestvariant) || abTest.default) : abTest.default
        } else {
          const cookie = store.$cookies.get('_hod_s') || {}
          const cookieTestMap = cookie?.abt?.length > 0 ? new Map(cookie?.abt) : new Map()
          const testVersion = cookieTestMap.get(abTest.id) || 1 + (Math.floor(Math.random() * abTest.variants) % abTest.variants)
          cookieTestMap.set(abTest.id, testVersion)
          hodGa4Cookie(store, { abt: Array.from(cookieTestMap, ([name, value]) => ([name, value])) })
          return testVersion
        }
      } else if (abTest) {
        return abTest.default
      } else {
        return -1
      }
    },
    croTestApplied: (testName, testVersion, formChoice = undefined) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).croTestApplied(testName, testVersion, formChoice)
      })
    },
    croTestConversion: (testName, conversionNumber, formChoice = undefined) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).croTestConversion(testName, conversionNumber, formChoice)
      })
    },
    sendErrorEvent: (errorCode, errorMessage) => {
      tools.forEach((tool) => {
        tool({ $config, store, route }, abTests).sendErrorEvent(errorCode, errorMessage)
      })
    }
  })
}

function hodGa4Cookie (store, data = undefined) {
  // const cookieConsent = store.$cookies.get('_hod_consent')
  const cookie = store.$cookies.get('_hod_s')
  const cookieG = store.$cookies.get('_hod_g')
  const payload = {
    a: cookie?.a || (cookieG?.a || (data?.user_id || undefined)),
    b: cookie?.b || (cookieG?.c || (data?.email_address ? crypto.createHash('sha256').update(data?.email_address.toLowerCase()).digest('hex') : undefined)),
    c: Date.now(),
    d: (Date.now() - cookie?.c < 1000 * 60 * 30) ? cookie?.d : (cookie?.a || (cookieG?.a || (data?.user_id || undefined)))?.toString() + '-' + Date.now().toString() + '-' + Math.round(Math.random() * 1023).toString(),
    e: (Date.now() - cookie?.c < 1000 * 60 * 30) ? cookie?.e : (cookie?.e + 1 || 1),
    f: (Date.now() - cookie?.c < 1000 * 60 * 30) ? cookie?.f : (cookie?.d || undefined),
    npu: data?.npu || cookie?.npu,
    npus: data?.npus || cookie?.npus,
    abt: data?.abt || cookie?.abt
  }
  store.$cookies.set('_hod_s', payload, {
    maxAge: 60 * 60 * 24 * 365,
    path: '/',
    sameSite: 'lax'
  })
}
