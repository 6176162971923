import { mapGetters } from 'vuex'

export default {
  props: {
    hideDescription: {
      type: Boolean,
      default: false
    },
    redirectUrl: {
      type: String,
      default: null
    },
    secretPage: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loginEmail: '',
      loginPassword: '',
      errorMessages: {}
    }
  },

  computed: {
    ...mapGetters({
      settings: 'storyblok/settings'
    }),
    selection () {
      return this.$store.state['centra-cart'].selection ? this.$store.state['centra-cart'].selection.selection : null
    },
    memberLoginContent () {
      return this.settings?.member_login_content?.html || ''
    },
  },

  methods: {
    validateEmail (email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },
    nl2br (str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },

    activateSection (section) {
      if (section === 'register') {
        this.$emit('activateSection', 'register')
        this.$analytics.selectContent('account', 'login', 'goToRegister', undefined)
      }
      if (section === 'login') {
        this.$emit('activateSection', 'login')
      }
      if (section === 'recover') {
        this.$emit('activateSection', 'recover')
        this.$analytics.selectContent('account', 'login', 'goToRecover', undefined)
      }
    },

    async login () {
      if (!this.validateEmail(this.loginEmail) || !(this.loginPassword?.length > 0)) {
        return
      }
      this.errorMessages = {}
      const res = await this.$store.dispatch('member/login', { email: this.loginEmail, password: this.loginPassword })
      this.$analytics.selectContent('account', 'login', 'tryLogin', undefined)
      if (res.errors?.email) {
        this.$set(this.errorMessages, 'email', 'Email incorrect')
      }
      if (res.errors?.password) {
        this.$set(this.errorMessages, 'password', 'Password incorrect')
      }
      if (!res.errors) {
        // this will close drawer needs fix
        this.$emit('loggedIn')
        this.$emit('loggingIn')

        if (!this.secretPage) {
          this.$router.push(this.redirectUrl ? this.$u(this.redirectUrl) : this.$u('account'))
        }
        this.$analytics.login()
      }
    }
  }
}
