export default {
  props: {
    headlineSize: {
      type: String,
      default: 'sm'
    },
    headline: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      loginEmail: '',
      message: null,
      errorMessages: {}
    }
  },
  methods: {
    validateEmail (email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },
    activateSection (section) {
      if (section === 'register') {
        this.$emit('activateSection', 'register')
      }
      if (section === 'login') {
        this.$emit('activateSection', 'login')
        this.$analytics.selectContent('account', 'recover', 'goToLogin', undefined)
      }
      if (section === 'reset') {
        this.$emit('activateSection', 'reset')
      }
    },

    async recover () {
      if (!this.validateEmail(this.loginEmail)) {
        return
      }
      this.errorMessages = {}
      const res = await this.$store.dispatch('member/resetPasswordEmail', this.loginEmail)
      if (res.errors) {
        this.$set(this.errorMessages, 'email', res.errors?.email ?? 'Something went wrong')
      } else {
        this.message = 'A link with instruction has been sent to yor email'
      }
      this.$analytics.selectContent('account', 'recover', 'tryRecover', undefined)
    }
  }
}
