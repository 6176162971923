import { mapGetters, mapState } from 'vuex'
import FilterOption from './FilterOption.vue'

export default {
  name: 'ProductFilter',
  components: {
    FilterOption
  },
  computed: {
    ...mapState({
      filterObject: state => state.ui.filterObject,
    }),
    ...mapGetters({
      getAlgoliaFacets: 'algolia/getFacets',
    }),
    facets () {
      if (this.filterObject.productsFrom === 'from-algolia') {
        return this.getAlgoliaFacets(this.filterObject.algoliaPayload.query, this.filterObject.algoliaPayload.searchParameters, this.filterObject.algoliaPayload.rankingIndex, this.filterObject.algoliaPayload.marketId)
      }
    },
  },
  methods: {
    hideMobileFilter () {
      this.$store.dispatch('ui/closeAll')
      this.$analytics.selectContent('utilities', 'filter', 'hideFilter', undefined)
    },
    resetFilters () {
      if (this.filterObject.productsFrom === 'from-algolia') {
        this.$store.dispatch('algolia/resetFilters', this.filterObject.algoliaPayload)
      }
    },
    clearFilter (facet) {
      if (this.filterObject.productsFrom === 'from-algolia') {
        this.$store.dispatch('algolia/clearFilterKey', { ...this.filterObject.algoliaPayload, filterKey: facet.key })
      }
    },
    toggleFacet (facet, option) {
      if (this.filterObject.productsFrom === 'from-algolia') {
        this.$store.dispatch('algolia/toggleFilter', { ...this.filterObject.algoliaPayload, filter: { key: facet.key, value: option.value } })
      }
    },
    sortedFacetValues (facetValue) {
      const fixedOrderedList = [
        'XXS',
        'XS',
        'XS/S',
        'S',
        'M',
        'M/L',
        'L',
        'XL',
        'One Size',
      ]
      return facetValue.sort((a, b) => {
        if (fixedOrderedList.includes(a.value) || fixedOrderedList.includes(b.value)) {
          return fixedOrderedList.indexOf(a.value) - fixedOrderedList.indexOf(b.value)
        }
        return a.value > b.value ? 1 : -1
      })
    },
  }
}
