import { mapState, mapGetters } from 'vuex'
import NewsletterPopUp from '@/components/newsletter-pop-up/NewsletterPopUp.vue'
import OpenChatButton from '@/components/open-chat-button/OpenChatButton.vue'
import NotifyPopUp from '@/components/notify-pop-up/NotifyPopUp.vue'
import CookieBanner from '@/components/cookie-banner/CookieBanner.vue'
import Footer from '@/components/footer/Footer.vue'
import Header from '@/components/header/Header.vue'
import Topbar from '@/components/topbar/Topbar.vue'
import CountryPickerPopUp from '@/components/country-picker-pop-up/CountryPickerPopUp.vue'
import ProductFilterV2 from '@/components/product-filter-v2/ProductFilter.vue'
import SearchV2 from '@/components/search-v2/Search.vue'

export default {
  name: 'defaultLayout',
  components: { CountryPickerPopUp, CookieBanner, NotifyPopUp, OpenChatButton, NewsletterPopUp, Footer, Header, Topbar, ProductFilterV2, SearchV2 },
  computed: {
    ...mapState({
      countrySelectorOpen: state => state.ui.countrySelectorOpen,
      miniCartOpen: state => state.ui.miniCartOpen,
      markets: state => state['centra-market'].markets,
      searchOpen: state => state.ui.searchOpen,
      mobileProductFilterOpen: state => state.ui.mobileProductFilterOpen,
      mobileProductFilterV2Open: state => state.ui.mobileProductFilterV2Open,
      sizeGuideSidebarOpen: state => state.sizeguide.sizeGuideSidebarOpen,
      assistanceSidebarOpen: state => state.assistance.assistanceSidebarOpen,
      notifyMeSidebarOpen: state => state['notify-me'].notifyMeSidebarOpen,
      currentRoute: state => state.routes.currentRoute,
      countryInformation: state => state.dagmar.countryInformation
    }),
    ...mapGetters({
      getMenuById: 'storyblok/getMenuById',
      settings: 'storyblok/settings',
      countriesSorted: 'frontend/countriesSorted',
      currentCountryCode: 'frontend/currentCountryCode',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentSiteLangSlug: 'frontend/currentSiteLangSlug',
      getMarketSettings: 'storyblok/getMarketSettings',
      getMarketByCountryCode: 'frontend/getMarketByCountryCode'
    }),
    mainMenu () {
      return this.getMenuById('main-menu')
    },
    footerMenu () {
      return this.getMenuById('footer-menu')
    },
    topbarPlacement () {
      return this.countryInformation?.content?.topbarPlacement
    }
  },
  watch: {
    $route () {
      this.$store.dispatch('ui/closeAll')
    }
  },
  methods: {
    closeAllSidebars () {
      this.$store.dispatch('ui/closeAll')
    },
    closeFilter () {
      this.$store.dispatch('ui/hideMobileProductFilter')
    },
    closeSizeguide () {
      this.$store.dispatch('sizeguide/hideSizeGuideSidebar')
    },
    closeAssistance () {
      this.$store.dispatch('assistance/hideAssistanceSidebar')
    },
    closeNotifyMe () {
      this.$store.dispatch('notify-me/hideNotifyMeSidebar')
    }
  }
}
